import React from 'react';
import type { FC } from 'react';

import { EinsteinRouter, Route } from 'components/_lib';
import CreateScreen from './CreateScreen';
import EditScreen from './EditScreen';
import ManageScreen from './ManageScreen';
import SelectScreen from './SelectScreen';

/**
 * Parent profile sub-router, routes to:
 *   /profile/select - Select active student
 *   /profile/manage - Manage student accounts
 */
const ProfileRouter: FC<{}> = () => (
  <EinsteinRouter>
    <Route path="/select" component={SelectScreen} />
    <Route path="/manage" component={ManageScreen} />
    <Route path="/edit" component={EditScreen} />
    <Route path="/create" component={CreateScreen} />
  </EinsteinRouter>
);

export default ProfileRouter;
