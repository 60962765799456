import React, { useState } from 'react';
import type { FC } from 'react';
import { navigate } from 'gatsby';
import { format, parseISO } from 'date-fns';

import {
  Box,
  Flex,
  Grid,
  IconButton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import { FaCartPlus } from 'react-icons/fa';

import { useParent } from 'hooks';
import colors from 'theme/colors';
import type { Booking, Subscription } from 'types';
import { getNextInstanceOfAvailability } from 'utils';

import { ButtonGatsbyLink, EinsteinButton } from 'components/_lib';
import { withSEO, withSpacing, withSubscription } from 'components/_hoc';
import SubscriptionActionsModal from './SubscriptionActionsModal';
import { ActionTypes } from './SubscriptionActionsModal/types';
import plans, { missileFat, rocketLarge, ufoFlat } from './plans';

const ALL_PLANS = [
  ...plans.spanish,
  ...plans.spanishOneOnOne,
  ...plans.literacy['Einstein Studios'],
  ...plans.literacy.PRIDE,
  ...plans.admin,
];

const DEFAULT_SPANISH_IMAGE = rocketLarge;
const DEFAULT_SPANISH_ONE_ON_ONE_IMAGE = missileFat;
const DEFAULT_LITERACY_IMAGE = ufoFlat;

// SubscriptionManager component prop types
interface SubscriptionManagerProps extends Subscription {
  credits: number;
  bookings: Booking[];
  handleOpen: (action: ActionTypes) => void;
}

// PlanDetails component prop types
type PlanDetailsProps = Subscription &
  Pick<SubscriptionManagerProps, 'handleOpen'>;

// UsageDetails component prop types
type UsageDetailsProps = Omit<SubscriptionManagerProps, 'handleOpen'>;

/**
 * Subscription plan information and action buttons
 */
const PlanDetails: FC<PlanDetailsProps> = ({
  handleOpen,
  ...subscription
}: PlanDetailsProps) => {
  const isDowngrading = subscription.schedule !== null;

  const currentPlan = isDowngrading
    ? subscription.schedule!.currentPhase.plan
    : subscription.plan;

  const image =
    ALL_PLANS.find((plan) => plan.id === currentPlan.id)?.picture ||
    (currentPlan.subject === 'Spanish' && currentPlan.isOneOnOne
      ? DEFAULT_SPANISH_ONE_ON_ONE_IMAGE
      : currentPlan.subject === 'Spanish'
      ? DEFAULT_SPANISH_IMAGE
      : DEFAULT_LITERACY_IMAGE);

  const price = currentPlan.price - (subscription.coupon?.amountOff || 0);

  const interval =
    currentPlan.intervalCount === 1
      ? currentPlan.interval
      : `${currentPlan.intervalCount} ${currentPlan.interval}s`;

  const nextPlan = isDowngrading
    ? subscription.schedule!.nextPhase.plan
    : undefined;

  const isCancelling = !isDowngrading && subscription.cancelsAt !== null;

  return (
    <Stack spacing={8} p={8} align="center" justify="center">
      <Flex
        flexDirection={['column', null, 'row']}
        align="center"
        justify="center"
        w="100%"
      >
        {image}
        <Stack
          ml={[0, null, 8]}
          spacing={8}
          w={['90%', '60%', '50%']}
          textAlign={['center', null, 'left']}
        >
          <Stack>
            <Text fontWeight="bold">{currentPlan.name}</Text>
            <Text color={subscription.coupon ? 'green.500' : 'teal.500'}>
              {`$${price}/${interval}`}{' '}
              {subscription.coupon &&
                `($${subscription.coupon.amountOff} for ${subscription.coupon.duration} with coupon)`}
            </Text>
            <Text>
              {`${currentPlan.classesPerWeek} recurring ${
                currentPlan.classesPerWeek === 1 ? 'class' : 'classes'
              }/week`}
            </Text>
          </Stack>
          <Stack pos="relative" w={['95%', null, '100%']}>
            <Flex align="center" justify="space-between">
              <Text>Activated:</Text>
              <Text fontWeight="bold">
                {format(new Date(subscription.startedAt), 'P')}
              </Text>
            </Flex>
            {!subscription.cancelsAt && (
              <Flex align="center" justify="space-between">
                <Text>Next payment:</Text>
                <Text fontWeight="bold">
                  {format(new Date(subscription.renewsAt), 'P')}
                </Text>
              </Flex>
            )}
            {(isDowngrading || isCancelling) && (
              <Flex
                align="center"
                justify="space-between"
                color={isDowngrading ? 'orange.500' : 'red.500'}
                w="100%"
              >
                <Text>
                  {isDowngrading
                    ? `Downgrades to ${nextPlan!.name}:`
                    : 'Cancels at:'}
                </Text>
                <Text fontWeight="bold">
                  {format(
                    parseISO(
                      isDowngrading
                        ? subscription.schedule!.nextPhase.startDate
                        : subscription.cancelsAt!
                    ),
                    'P'
                  )}
                </Text>
                <IconButton
                  aria-label="cancel"
                  icon="delete"
                  pos="absolute"
                  right="-3rem"
                  variant="ghost"
                  variantColor={isDowngrading ? 'orange' : 'red'}
                  onClick={() =>
                    handleOpen(
                      isDowngrading
                        ? ActionTypes.cancelUpdate
                        : ActionTypes.cancelCancellation
                    )
                  }
                />
              </Flex>
            )}
          </Stack>
        </Stack>
      </Flex>
      <Flex flexDir={['column', null, 'row']}>
        <EinsteinButton
          variantColor="red"
          variant="outline"
          size="lg"
          w="170px"
          mr={[2, 4, 6, 8]}
          onClick={() => handleOpen(ActionTypes.cancelSubscription)}
          isDisabled={subscription.cancelsAt !== null}
        >
          Cancel Plan
        </EinsteinButton>
        <ButtonGatsbyLink
          variantColor="orange"
          mt={[4, null, 0]}
          size="lg"
          w="170px"
          to={`/parent/subscriptions/purchase?subject=${subscription.plan.subject}&subscription=${subscription.id}`}
          isDisabled={subscription.schedule !== null}
        >
          Change Plan
        </ButtonGatsbyLink>
      </Flex>
      <Stack spacing={4}>
        <EinsteinButton
          textTransform="none"
          fontFamily="body"
          variant="link"
          onClick={() => handleOpen(ActionTypes.changePaymentMethod)}
        >
          Update payment details.
        </EinsteinButton>
      </Stack>
    </Stack>
  );
};

// CSS dot shape
const Dot = styled.div`
  height: 18px;
  width: 18px;
  background-color: ${colors.teal['200']};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
`;

/**
 * Class credit and subscription status information
 */
const UsageDetails: FC<UsageDetailsProps> = ({
  credits,
  bookings,
  ...subscription
}: UsageDetailsProps) => {
  const remainingBookings = subscription!.plan.classesPerWeek - bookings.length;

  return (
    <Stack
      spacing={8}
      p={8}
      backgroundColor="teal.500"
      roundedBottomRight={8}
      roundedTopRight={['none', null, 8]}
      roundedBottomLeft={[8, null, 'none']}
      align="center"
      justify="center"
    >
      <Stack isInline align="center">
        <Dot />
        <Text textTransform="uppercase" fontWeight="bold" color="teal.200">
          {subscription!.status}
        </Text>
      </Stack>
      <Stack spacing={[4, null, 8]}>
        {bookings.length > 0 && (
          <Box textAlign="center">
            <Text color="gray.300">Recurring Bookings:</Text>
            {bookings?.map(({ id, availability: { dayOfWeek, startTime } }) => (
              <Text key={id} fontSize="lg" fontWeight="bold" color="white">
                {`${dayOfWeek.charAt(0)}${dayOfWeek
                  .slice(1, dayOfWeek.length)
                  .toLowerCase()}`}
                s at{' '}
                {format(
                  getNextInstanceOfAvailability(
                    dayOfWeek,
                    startTime,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  ),
                  'p'
                )}
              </Text>
            ))}
          </Box>
        )}
        {remainingBookings > 0 && (
          <Box textAlign="center">
            <Text color="gray.300">Unused Bookings:</Text>
            <Text fontSize="lg" fontWeight="bold" color="white">
              {remainingBookings}
            </Text>
          </Box>
        )}
        {credits > 0 && (
          <Box textAlign="center">
            <Text color="gray.300">Class Credits:</Text>
            <Text fontSize="lg" fontWeight="bold" color="white">
              {credits}
            </Text>
          </Box>
        )}
        <ButtonGatsbyLink
          textTransform="none"
          fontFamily="body"
          variant="link"
          to={`/parent/subscriptions/manage/bookings?subject=${subscription.plan.subject}`}
        >
          Manage
        </ButtonGatsbyLink>
      </Stack>
    </Stack>
  );
};

/**
 * Manages an individual subscription
 */
const SubscriptionManager: FC<SubscriptionManagerProps> = ({
  credits,
  bookings,
  handleOpen,
  ...subscription
}: SubscriptionManagerProps) => (
  <Grid
    w="100%"
    h={['auto', null, '450px']}
    color="teal.500"
    gridTemplateColumns={['none', null, '75% 25%']}
    gridTemplateRows={['auto auto', null, 'none']}
  >
    <PlanDetails handleOpen={handleOpen} {...subscription} />
    <UsageDetails credits={credits} bookings={bookings} {...subscription} />
  </Grid>
);

/**
 * Controls active SubscriptionManager
 */
const SubscriptionManagerTabs: FC = () => {
  const { profile } = useParent()!;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [actionType, setActionType] = useState<ActionTypes>();

  const defaultTabIndex = !profile.subjects.Literacy.subscription ? 1 : 0;
  const [tabIndex, setTabIndex] = useState<number>(defaultTabIndex);

  const handleTabsChange = (index: number): void => {
    if (index === 0 && !profile.subjects.Literacy.subscription) {
      navigate('/parent/subscriptions/purchase?subject=Literacy');
    } else if (index === 1 && !profile.subjects.Spanish.subscription) {
      navigate('/parent/subscriptions/purchase?subject=Spanish');
    } else {
      setTabIndex(index);
    }
  };

  const handleOpen = (action: ActionTypes): void => {
    setActionType(action);
    onOpen();
  };

  const handleClose: VoidFunction = () => {
    setActionType(undefined);
    onClose();
  };

  return (
    <>
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        variant="unstyled"
        size="md"
        margin="0 auto"
        w="100%"
        defaultIndex={defaultTabIndex}
      >
        <TabList
          borderLeft="1px solid"
          borderColor="gray.500"
          roundedTopLeft={8}
        >
          <Tab
            w="130px"
            color="teal.500"
            backgroundColor="gray.300"
            fontWeight="bold"
            border="1px solid"
            px={8}
            py={3}
            borderColor="gray.500"
            roundedTop={8}
            mb="-1px"
            _first={{
              marginRight: 1,
              borderLeft: 'none',
            }}
            _selected={{
              bg: 'white',
              borderBottom: '1px solid transparent',
            }}
          >
            {profile.subjects.Literacy.subscription ? (
              'Literacy'
            ) : (
              <Flex align="center">
                Literacy
                <Box as={FaCartPlus} ml={1} />
              </Flex>
            )}
          </Tab>
          <Tab
            w="130px"
            color="teal.500"
            backgroundColor="gray.300"
            fontWeight="bold"
            border="1px solid"
            px={8}
            py={3}
            borderColor="gray.500"
            roundedTop={8}
            mb="-1px"
            _first={{
              marginRight: 1,
              borderLeft: 'none',
            }}
            _selected={{
              bg: 'white',
              borderBottom: '1px solid transparent',
            }}
          >
            {profile.subjects.Spanish.subscription ? (
              'Spanish'
            ) : (
              <Flex align="center">
                Spanish
                <Box as={FaCartPlus} ml={1} />
              </Flex>
            )}
          </Tab>
        </TabList>

        <TabPanels
          border="1px solid"
          borderColor="gray.500"
          backgroundColor="white"
          rounded={8}
          color="teal.500"
          roundedTopLeft={0}
          boxShadow="lg"
        >
          <TabPanel>
            {profile.subjects.Literacy.subscription && (
              <SubscriptionManager
                credits={profile.subjects.Literacy.credits}
                bookings={profile.subjects.Literacy.bookings}
                handleOpen={handleOpen}
                {...profile.subjects.Literacy.subscription}
              />
            )}
          </TabPanel>
          <TabPanel>
            {profile.subjects.Spanish.subscription && (
              <SubscriptionManager
                credits={profile.subjects.Spanish.credits}
                bookings={profile.subjects.Spanish.bookings}
                handleOpen={handleOpen}
                {...profile.subjects.Spanish.subscription}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      {actionType && (
        <SubscriptionActionsModal
          subscription={
            tabIndex === 0
              ? profile.subjects.Literacy.subscription
              : profile.subjects.Spanish.subscription
          }
          isOpen={isOpen}
          onClose={handleClose}
          type={actionType}
        />
      )}
    </>
  );
};

export default withSEO(withSubscription(withSpacing(SubscriptionManagerTabs)), {
  title: 'Manage Subscriptions • Parent',
  description:
    'Manage your Einstein Studios subscriptions. Upgrade your plan, update payment details, view class credits and bookings, cancel your subscription, or purchase a new one!',
  pathname: '/parent/subscriptions/manage',
});
