import React from 'react';
import type { FC } from 'react';

import { withSEO } from 'components/_hoc';
import { EinsteinRouter, Route } from 'components/_lib';

import ClassTypeMenu from './ClassTypeMenu';
import OneTimeClassMenu from './OneTimeClassMenu';
import RecurringClassMenu from './RecurringClassMenu';
import SubjectMenu from './SubjectMenu';

/**
 * Class booking page sub-router. Routes to:
 *   /parent/clases - Select availabilities subject
 *   /parent/classes/:subject - Subject availabilities type
 *   /parent/classes/:subject/recurring - Book recurring classes
 *   /parent/classes/:subject/one_time - Book one-time classes
 */
const ClassesRouter: FC<{}> = () => (
  <EinsteinRouter>
    <Route path="/" component={SubjectMenu} />
    <Route path="/:subject" component={ClassTypeMenu} />
    <Route path="/:subject/one_time" component={OneTimeClassMenu} />
    <Route path="/:subject/recurring" component={RecurringClassMenu} />
  </EinsteinRouter>
);

export default withSEO(ClassesRouter, {
  title: 'Classes • Parent',
  description:
    'Book one-time classes or create recurring class bookings with Einstein Studios.',
  pathname: '/parent/classes',
});
