import React, { memo, useState } from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';

import {
  Box,
  Flex,
  Heading,
  PseudoBox,
  Link,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';

import {
  EinsteinButton,
  MobileSection,
  MobileSectionGroup,
  MobileSectionItem,
  MobileSectionTitle,
  Section,
} from 'components/_lib';
import { withSEO, withSpacing, withSubscription } from 'components/_hoc';
import { useParent } from 'hooks';
import fontSizes from 'theme/fontSizes';
import { parseQueryString } from 'utils';

import SubscriptionActionsModal, {
  ActionTypes,
} from './SubscriptionActionsModal';
import plans from './plans';
import type { PlanElement } from './plans';

// PlanComponent component prop types
interface PlanComponentProps extends PlanElement {
  isLast: boolean;
  isActive: boolean;
  handleClick: VoidFunction;
}

/**
 * Package get started component
 */
const PlanComponent: FC<PlanComponentProps> = memo(
  ({
    name,
    classesPerWeek,
    price,
    interval,
    intervalCount,
    picture,
    features,
    isLast,
    isActive,
    handleClick,
  }: PlanComponentProps) => {
    const stats = (
      <>
        <Stack isInline justify="center">
          <Text lineHeight={fontSizes['4xl']}>$</Text>
          <Text fontWeight="bold" fontSize="4xl" fontFamily="heading">
            {price}
          </Text>
          <Text lineHeight={fontSizes['4xl']} alignSelf="flex-end">
            /{intervalCount === 1 ? interval : `${intervalCount} ${interval}s`}
          </Text>
        </Stack>
        <Text textDecoration="underline" textAlign="center">
          {classesPerWeek} recurring{' '}
          {classesPerWeek === 1 ? 'class' : 'classes'}
          /week
        </Text>
      </>
    );

    const getStartedButton = (
      <EinsteinButton
        variant="outline"
        size="lg"
        onClick={handleClick}
        isDisabled={isActive}
      >
        {isActive ? 'Subscribed' : 'Get Started'}
      </EinsteinButton>
    );

    return (
      <>
        {/* Mobile version */}
        <MobileSection mb={isLast ? 0 : 8} w={['100%', '80%']}>
          <MobileSectionTitle>{name}</MobileSectionTitle>
          <MobileSectionGroup>
            <MobileSectionItem justifyContent="center">
              <Stack spacing={8}>
                <Stack isInline spacing={8} align="center" justify="center">
                  <Flex w="125px">{picture}</Flex>
                  <Stack spacing={4}>{stats}</Stack>
                </Stack>
                <Box>{features}</Box>
                {getStartedButton}
              </Stack>
            </MobileSectionItem>
          </MobileSectionGroup>
        </MobileSection>

        {/* Desktop version */}
        <Section
          p={4}
          spacing={8}
          borderRight={isLast ? 'none' : '1px solid'}
          borderColor="gray.500"
        >
          {picture}
          <Stack spacing={2} textAlign="center">
            <Heading size="md" fontFamily="heading" textTransform="uppercase">
              {name}
            </Heading>
            {stats}
          </Stack>
          <Box>{features}</Box>
          {getStartedButton}
        </Section>
      </>
    );
  }
);

/**
 * Subscription purchase or change page
 */
const SubscriptionMenu: FC = () => {
  const { profile } = useParent()!;
  const { search } = useLocation();

  const subscriptionQuery = parseQueryString('subscription', search);
  const subscriptionToChange = Object.values(profile.subjects).find(
    ({ subscription }) => subscription?.id === subscriptionQuery
  )?.subscription;

  const subjectQuery = parseQueryString('subject', search)?.toLowerCase();

  const allPlans = {
    literacy: plans.literacy[profile.subjects.Literacy.team],
    spanish: plans.spanish,
    spanishOneOnOne: plans.spanishOneOnOne,
  };

  const [activeSubject, setActiveSubject] = useState<
    'literacy' | 'spanish' | 'spanishOneOnOne'
  >(subjectQuery === 'spanish' ? 'spanish' : 'literacy');

  // StripeModal state handlers
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Selected plan
  const [newPlan, setNewPlan] = useState<PlanElement>();
  const handleOpen = (plan: PlanElement): void => {
    setNewPlan(plan);
    onOpen();
  };

  return (
    <>
      <Stack spacing={8} w="100%">
        <Flex w="100%">
          <Select
            variant="flushed"
            w="50%"
            m="0 auto"
            focusBorderColor="orange.500"
            _hover={{ borderColor: 'gray.300' }}
            fontWeight="bold"
            color="teal.500"
            value={activeSubject}
            onChange={({ target: { value } }) =>
              setActiveSubject(
                value as 'literacy' | 'spanish' | 'spanishOneOnOne'
              )
            }
          >
            {subjectQuery !== 'spanish' && (
              <option value="literacy">
                {profile.subjects.Literacy.team === 'Einstein Studios'
                  ? 'Literacy'
                  : 'PRIDE Literacy'}
              </option>
            )}
            {subjectQuery !== 'literacy' && (
              <option value="spanish">Spanish</option>
            )}
            {subjectQuery !== 'literacy' && (
              <option value="spanishOneOnOne">Spanish 1-on-1</option>
            )}
          </Select>
        </Flex>
        <Flex
          align="center"
          w="100%"
          justify={['center', null, 'space-evenly']}
          flexDirection={['column', null, 'row']}
        >
          {allPlans[activeSubject].map((plan, index) => (
            <PlanComponent
              key={plan.id}
              isLast={index === allPlans[activeSubject].length - 1}
              isActive={plan.id === subscriptionToChange?.plan.id}
              handleClick={(): void => handleOpen(plan)}
              {...plan}
            />
          ))}
        </Flex>
        <PseudoBox
          d="flex"
          bg="white"
          rounded={8}
          m="0 auto"
          color="teal.500"
          w="100%"
          alignItems="center"
          justifyContent="center"
          p={8}
        >
          <Text fontStyle="italic" textAlign="center">
            Looking for more classes per week?{' '}
            <Link href="mailto:albert@einsteinstudios.io" color="orange.500">
              Reach out to us
            </Link>{' '}
            to create a customized plan!
          </Text>
        </PseudoBox>
      </Stack>
      {newPlan && (
        <SubscriptionActionsModal
          isOpen={isOpen}
          onClose={onClose}
          plan={newPlan}
          subscription={subscriptionToChange}
          type={
            subscriptionToChange !== undefined
              ? ActionTypes.changeSubscription
              : ActionTypes.purchase
          }
        />
      )}
    </>
  );
};

export default withSEO(withSubscription(withSpacing(SubscriptionMenu)), {
  title: 'Purchase Subscription • Parent',
  description:
    'Subscribe to a new plan. With a subscription, you can book up to two recurring VR classes a week.',
  pathname: '/parent/subscriptions/purchase',
});
