import React, { useState } from 'react';
import type { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { useMutation } from 'react-query';

import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/core';

import API from 'api';
import EinsteinStudiosLogoUpright from 'assets/svgs/einstein-studios-logo-upright.svg';
import ValidationCircleSvg from 'assets/svgs/validation-circle.svg';
import { EinsteinButton, ErrorMessage } from 'components/_lib';
import type { ErrorState } from 'types';
import { capitalize } from 'utils';
import type { DeleteBookingState } from './types';

// Enumeration of modal content
enum ScreensEnum {
  confirm,
  deleted,
}

// DeleteBookingModal component prop types
interface DeleteBookingModalProps {
  booking: DeleteBookingState;
  isOpen: boolean;
  onClose: VoidFunction;
}

// DeletionConfirmation component prop types
interface DeletionConfirmationProps {
  booking: DeleteBookingState;
  transitionToDeletion: VoidFunction;
}

/**
 * Modal content for confirming booking deletion
 */
const DeletionConfirmation: FC<DeletionConfirmationProps> = ({
  booking,
  transitionToDeletion,
}: DeletionConfirmationProps) => {
  const [errorMessage, setErrorMessage] = useState<ErrorState>({
    isActive: false,
    message: '',
  });

  const [mutate, { status }] = useMutation(
    (): Promise<void> => API.delete(`/bookings/${booking.id}`),
    {
      onSuccess: (): void => {
        if (errorMessage.isActive) {
          setErrorMessage({ isActive: false, message: '' });
        }
        transitionToDeletion();
      },
      onError: (): void => {
        setErrorMessage({
          isActive: true,
          message: 'Could not cancel booking at this time.',
        });
      },
    }
  );

  return (
    <Stack
      spacing={6}
      color="teal.500"
      alignItems="center"
      p={8}
      textAlign="center"
    >
      <Text fontWeight="bold">Confirming booking deletion:</Text>
      <Stack isInline align="center" justify="center" spacing={8}>
        <Stack spacing={2} alignItems="center" textAlign="center">
          <Avatar size="lg" src={booking.student.picture || undefined} />
          <Text fontWeight="bold">{booking.student.username}</Text>
        </Stack>
        <Stack spacing={2} textAlign="left">
          <Text>
            <strong>Teacher:</strong> {booking.teacher.fullName}
          </Text>
          <Text>
            <strong>Subject:</strong> {booking.subject.name}
          </Text>
          <Text>
            <strong>Date & Time:</strong>{' '}
            {capitalize(booking.availability.dayOfWeek)}s,{' '}
            {format(
              parseISO(
                `${format(Date.now(), 'yyyy-MM-dd')} ${
                  booking.availability.startTime
                }`
              ),
              'p'
            )}
          </Text>
        </Stack>
      </Stack>
      <Text>
        <i>
          Upon clicking &quot;delete booking&quot; below,{' '}
          {booking.student.username} will be unattended from all upcoming
          classes associated with the above details.
        </i>
      </Text>
      <EinsteinButton
        minW="130px"
        variant="outline"
        variantColor="red"
        textTransform="uppercase"
        size="lg"
        fontFamily="heading"
        onClick={() => mutate()}
        isLoading={status === 'loading'}
        loadingText="Deleting..."
      >
        Delete Booking
      </EinsteinButton>
      <ErrorMessage isActive={errorMessage.isActive}>
        {errorMessage.message}
      </ErrorMessage>
    </Stack>
  );
};

/**
 * Booking successfully deleted note
 */
const DeletedScreen: FC<{}> = () => (
  <Stack spacing={8} color="teal.500" alignItems="center" p={8}>
    <Stack spacing={4} alignItems="center">
      <Flex w="6rem" h="6rem">
        <ValidationCircleSvg />
      </Flex>
      <Text fontWeight="bold">Booking successfully deleted</Text>
    </Stack>
    <Text textAlign="center">
      Your student has been unattended from all upcoming classes associated with
      this recurring class.
    </Text>
    <EinsteinButton
      size="lg"
      aria-label="back"
      variantColor="orange"
      variant="outline"
      onClick={(): void => window.location.reload()}
    >
      Done
    </EinsteinButton>
  </Stack>
);

/**
 * Modal to confirm a booking deletion
 */
const DeleteBookingModal: FC<DeleteBookingModalProps> = ({
  booking,
  isOpen,
  onClose,
}: DeleteBookingModalProps) => {
  // Current modal screen
  const [screen, setScreen] = useState<ScreensEnum>(ScreensEnum.confirm);

  /**
   * Move to deletion successful screen
   */
  const transitionToDeletion: VoidFunction = () => {
    setScreen(ScreensEnum.deleted);
  };

  return (
    <Modal
      size={['full', null, 'lg']}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      preserveScrollBarGap
      closeOnOverlayClick={screen === ScreensEnum.confirm}
    >
      <ModalOverlay />
      <ModalContent rounded={['none', null, 8]}>
        <IconButton
          variant="ghost"
          variantColor="teal"
          aria-label="close"
          icon="close"
          w="min-content"
          alignSelf="flex-start"
          rounded={8}
          mt={2}
          ml={2}
          onClick={
            screen === ScreensEnum.confirm
              ? onClose
              : (): void => window.location.reload()
          }
        />
        <ModalBody
          d="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box w="100px">
            <EinsteinStudiosLogoUpright />
          </Box>
          {screen === ScreensEnum.confirm ? (
            <DeletionConfirmation
              booking={booking}
              transitionToDeletion={transitionToDeletion}
            />
          ) : (
            <DeletedScreen />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteBookingModal;
