import React from 'react';
import type { FC } from 'react';

import { EinsteinRouter, Route } from 'components/_lib';
import ManageBookings from './ManageBookings';
import ManageSubscription from './SubscriptionManager';
import PurchaseSubscription from './SubscriptionMenu';

/**
 * Wraps parent subscription page
 */
const SubscriptionRouter: FC = () => (
  <EinsteinRouter>
    <Route path="/purchase" component={PurchaseSubscription} />
    <Route path="/manage" component={ManageSubscription} />
    <Route path="/manage/bookings" component={ManageBookings} />
  </EinsteinRouter>
);

export default SubscriptionRouter;
