import React, { memo, useState } from 'react';
import type { FC } from 'react';
import { useMutation } from 'react-query';

import { Heading, Stack, Text } from '@chakra-ui/core';

import API from 'api';
import { EinsteinButton, ErrorMessage } from 'components/_lib';
import type { ErrorState, Subscription } from 'types';
import { ActionTypes, SubscriptionActionsModalContainerProps } from './types';

// CancelBody component prop types
interface CancelBodyProps
  extends Pick<SubscriptionActionsModalContainerProps, 'type'> {
  subscription: Subscription;
  navigateToThankYou: VoidFunction;
}

/**
 * Modal body for processing different subscription cancel events
 */
const CancelBody: FC<CancelBodyProps> = ({
  navigateToThankYou,
  subscription,
  type,
}: CancelBodyProps) => {
  // Request errors
  const [requestError, setRequestError] = useState<ErrorState>({
    isActive: false,
    message: '',
  });

  const [mutate, { status }] = useMutation(
    async (): Promise<void> => {
      if (type === ActionTypes.cancelCancellation) {
        await API.delete(`/subscriptions/${subscription.id}/cancel`);
      } else if (type === ActionTypes.cancelSubscription) {
        await API.put(`/subscriptions/${subscription.id}/cancel`);
      } else {
        await API.delete(
          `/subscriptions/schedule/${subscription.schedule!.id}`
        );
      }
    },
    {
      onSuccess: () => {
        setRequestError({ isActive: false, message: '' });
        navigateToThankYou();
      },
      onError: () => {
        setRequestError({
          isActive: true,
          message: 'Could not process cancellation',
        });
      },
    }
  );

  return (
    <Stack spacing={8} w="100%" align="center" justify="center" mb={4}>
      <Stack spacing={4} textAlign="center" w="90%" align="center">
        <Heading size="sm" textTransform="uppercase">
          Are you sure?
        </Heading>
        <Text>
          By clicking &quot;confirm&quot; below,{' '}
          {type === ActionTypes.cancelCancellation
            ? 'your pending cancellation will be terminated and your current subscription will be preserved.'
            : type === ActionTypes.cancelSubscription
            ? 'you schedule your subscription to terminate at your next billing date.'
            : 'your pending downgrade will be terminated and your current subscription will be preserved.'}
        </Text>
        <EinsteinButton
          variant="outline"
          size="lg"
          minW="132px"
          onClick={() => mutate()}
          isLoading={status === 'loading'}
          loadingText="Cancelling..."
        >
          Confirm
        </EinsteinButton>
        <ErrorMessage isActive={requestError.isActive} w="80%">
          {requestError.message !== null && 'Could not process cancellation'}
        </ErrorMessage>
      </Stack>
    </Stack>
  );
};

export default memo(CancelBody);
