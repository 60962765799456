import React, { useState } from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';
import { useQuery } from 'react-query';
import type { QueryFunction } from 'react-query';
import { compareAsc, format, getDay, parseISO } from 'date-fns';

import Img from 'gatsby-image';
import {
  Avatar,
  Box,
  Heading,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';

import API from 'api';
import MoonSmoothSvg from 'assets/svgs/moon-smooth.svg';
import {
  BreadcrumbNav,
  MobileSection,
  MobileSectionGroup,
  MobileSectionTitle,
  Section,
  SectionBody,
  SectionTitle,
} from 'components/_lib';
import { withLayout } from 'components/_hoc';
import { useActiveStudent, useParent, useSubjectImages } from 'hooks';
import type { RecurringAvailability } from 'types';
import { capitalize, parseQueryString } from 'utils';
import { WEEKDAYS } from 'data';

import ConfirmationModal from '../ConfirmationModal';
import type {
  RecurringAvailabilityState,
  RecurringTeacherAvailabilities,
} from '../types';
import { CalendarWeek, MobileCalendarWeek } from './CalendarWeek';
import HelpBanner from './HelpBanner';

// Recurring availability type parsed from API results
interface LocalRecurringAvailability
  extends Pick<RecurringTeacherAvailabilities, 'teacher'> {
  availabilities: RecurringAvailability[][];
}

/*
 * === HELPERS ===
 */

/**
 * React Query function to fetch open recurring availabilities.
 */
const fetchRecurringAvailabilities: QueryFunction<
  LocalRecurringAvailability[],
  [string, { studentId: string; subject: string; isOneOnOne: string }]
> = async (_, params) => {
  try {
    const response = await API.get('/availabilities/recurring', { params });

    const today = format(Date.now(), 'yyyy-MM-dd');
    return response.data.map(
      ({ teacher, availabilities }: RecurringTeacherAvailabilities) => ({
        teacher,
        availabilities: availabilities
          .reduce(
            (
              sorted: LocalRecurringAvailability['availabilities'],
              availability: RecurringAvailabilityState['availability']
            ) => {
              const zoned = parseISO(availability.firstClass);
              const nextDay = getDay(zoned);

              return [
                ...sorted.slice(0, nextDay),
                [
                  ...sorted[nextDay],
                  {
                    ...availability,
                    startTime: format(zoned, 'HH:mm:ss'),
                    dayOfWeek: WEEKDAYS[nextDay].code,
                  },
                ],
                ...sorted.slice(nextDay + 1, sorted.length),
              ];
            },
            [[], [], [], [], [], [], []]
          )
          .map((dayOfWeek) =>
            dayOfWeek.sort((a, b) =>
              compareAsc(
                parseISO(`${today} ${a.startTime}`),
                parseISO(`${today} ${b.startTime}`)
              )
            )
          ),
      })
    );
  } catch {
    return [];
  }
};

/*
 * === CONSTANTS ===
 */

const NO_AVAILABILITIES = (
  <Stack
    spacing={4}
    p={8}
    align="center"
    bg="white"
    rounded={8}
    color="teal.500"
  >
    <Box w="100px">
      <MoonSmoothSvg />
    </Box>
    <Text fontWeight="bold">No open availabilities.</Text>
  </Stack>
);

// Loading skeletons
const SKELETONS = ['skeleton1', 'skeleton2'].map((key) => (
  <Skeleton key={key} h="200px" w="100%" rounded={8} />
));

/*
 * === COMPONENTS ===
 */

// RecurringAvailabilitiesMenu component prop types
interface RecurringAvailabilitiesMenuProps extends LocalRecurringAvailability {
  handleSelect: (availability: RecurringAvailabilityState) => void;
}

/**
 * Renders recurring availability options for a given teacher.
 */
const RecurringAvailabilitiesMenu: FC<RecurringAvailabilitiesMenuProps> = ({
  teacher,
  availabilities,
  handleSelect,
}: RecurringAvailabilitiesMenuProps) => (
  <Stack
    spacing={{ md: 8, lg: 0 }}
    w="100%"
    border="1px solid #979797"
    borderRadius="8px"
    p={8}
    color="teal.500"
    justifyContent="space-around"
    flexDirection={{ md: 'column', lg: 'row' }}
  >
    <Stack isInline spacing={4} justify="center" align="center">
      <Avatar size="xl" src={teacher.picture || undefined} />
      <Stack>
        <Heading size="md">{teacher.fullName}</Heading>
        {teacher.bio && (
          <Stack isInline align="center" spacing={1}>
            <Text>Bio</Text>
            <Popover placement="bottom-start">
              <PopoverTrigger>
                <IconButton
                  size="sm"
                  variant="ghost"
                  aria-label={`${teacher.fullName} bio`}
                  icon="info-outline"
                />
              </PopoverTrigger>
              <PopoverContent zIndex={4} maxW={{ md: '375px', lg: '500px' }}>
                <PopoverArrow />
                <PopoverBody>{teacher.bio}</PopoverBody>
              </PopoverContent>
            </Popover>
          </Stack>
        )}
      </Stack>
    </Stack>
    <CalendarWeek
      teacher={teacher}
      availabilities={availabilities}
      handleSelect={handleSelect}
    />
  </Stack>
);

/**
 * Renders recurring availability options for a given teacher on mobile.
 */
const MobileRecurringAvailabilitiesMenu: FC<RecurringAvailabilitiesMenuProps> = ({
  teacher,
  availabilities,
  handleSelect,
}: RecurringAvailabilitiesMenuProps) => (
  <MobileSection key={teacher.id}>
    <MobileSectionTitle avatar={<Avatar src={teacher.picture || undefined} />}>
      <Stack isInline align="center">
        <Heading size="md">{teacher.fullName}</Heading>
        {teacher.bio && (
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <IconButton
                size="sm"
                variant="ghost"
                aria-label={`${teacher.fullName} bio`}
                icon="info-outline"
              />
            </PopoverTrigger>
            <PopoverContent
              zIndex={4}
              w="400px"
              textTransform="none"
              fontWeight="normal"
            >
              <PopoverArrow />
              <PopoverBody>{teacher.bio}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Stack>
    </MobileSectionTitle>
    <MobileSectionGroup>
      <MobileCalendarWeek
        teacher={teacher}
        availabilities={availabilities}
        handleSelect={handleSelect}
      />
    </MobileSectionGroup>
  </MobileSection>
);

// RecurringClassMenu component prop types
interface RecurringClassMenuProps {
  subject: string;
}

/**
 * Menu of recurring class availabilities
 */
const RecurringClassMenu: FC<RecurringClassMenuProps> = ({
  subject,
}: RecurringClassMenuProps) => {
  const data = useSubjectImages();
  const location = useLocation();
  const { profile } = useParent()!;
  const { id: studentId } = useActiveStudent();

  const isOneOnOne =
    subject === 'literacy' ||
    profile.subjects.Spanish.subscription?.plan.isOneOnOne === true;

  const bookingId = parseQueryString('booking', location.search);
  const bookingToChange = profile.subjects[
    subject === 'literacy' ? 'Literacy' : 'Spanish'
  ].bookings.find((booking) => booking.id === bookingId);

  // ConfirmationModal state
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Fetch all recurring availabilites from API
  const { data: recurringAvailabilities, status } = useQuery(
    [
      'recurringAvailabilities',
      { studentId, subject, isOneOnOne: isOneOnOne ? 'true' : 'false' },
    ],
    fetchRecurringAvailabilities
  );

  // Chosen availability to book
  const [selected, setSelected] = useState<RecurringAvailabilityState>();

  /**
   * Select an availability as active and open the confirmation modal.
   *
   * @param availability - Availability to set active.
   */
  const handleSelect = (availability: RecurringAvailabilityState): void => {
    setSelected(availability);
    onOpen();
  };

  const subjectImage = (
    <Img
      style={{
        borderRadius: '100%',
        width: '2rem',
        height: '2rem',
      }}
      fluid={
        subject === 'spanish'
          ? data.spanishImage.childImageSharp.fluid
          : data.literacyImage.childImageSharp.fluid
      }
      alt={subject === 'spanish' ? 'Spanish flag' : 'stack of books'}
    />
  );

  return (
    <>
      <HelpBanner />

      {/* Section rendered on small screens */}
      <Stack d={['flex', null, 'none']} spacing={8} shouldWrapChildren>
        <MobileSection>
          <MobileSectionTitle
            backTo={`/parent/classes/${subject}`}
            avatar={subjectImage}
          >
            {subject}
          </MobileSectionTitle>
        </MobileSection>
        {status === 'loading' || !recurringAvailabilities
          ? SKELETONS
          : recurringAvailabilities.length === 0
          ? NO_AVAILABILITIES
          : recurringAvailabilities?.map(({ teacher, availabilities }) =>
              availabilities.length > 0 ? (
                <MobileRecurringAvailabilitiesMenu
                  key={teacher.id}
                  teacher={teacher}
                  availabilities={availabilities}
                  handleSelect={handleSelect}
                />
              ) : null
            )}
      </Stack>

      {/* Section rendered on large screens */}
      <>
        <BreadcrumbNav
          links={[
            {
              href: '/parent/classes',
              text: capitalize(subject),
              isCurrentPage: false,
            },
            {
              href: `/parent/classes/${subject}`,
              text: 'Recurring',
              isCurrentPage: false,
            },
            {
              href: location.pathname,
              text: 'Availabilities',
              isCurrentPage: true,
            },
          ]}
        />
        <Section>
          <SectionTitle avatar={subjectImage}>{subject}</SectionTitle>
          <SectionBody w="100%">
            {status === 'loading' || !recurringAvailabilities ? (
              <Stack spacing={8}>{SKELETONS}</Stack>
            ) : recurringAvailabilities.length === 0 ? (
              NO_AVAILABILITIES
            ) : (
              <Stack spacing={8} shouldWrapChildren>
                {recurringAvailabilities.map(({ teacher, availabilities }) =>
                  availabilities.length > 0 ? (
                    <RecurringAvailabilitiesMenu
                      key={teacher.id}
                      teacher={teacher}
                      availabilities={availabilities}
                      handleSelect={handleSelect}
                    />
                  ) : null
                )}
              </Stack>
            )}
          </SectionBody>
        </Section>
      </>

      {selected && (
        <ConfirmationModal
          type={bookingToChange ? 'change' : 'recurring'}
          teacher={selected.teacher}
          availability={selected.availability}
          bookingToChange={bookingToChange}
          subject={subject}
          isOneOnOne={isOneOnOne}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default withLayout(RecurringClassMenu);
