import React, { useRef, useState } from 'react';
import type { FC, ReactElement, RefObject } from 'react';
import { useMutation } from 'react-query';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Icon,
  IconButton,
  PseudoBox,
  Text,
  Tooltip,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import type { EditableProps, useToastOptions } from '@chakra-ui/core';

import API from 'api';
import { EinsteinButton, IconButtonGatsbyLink } from 'components/_lib';
import type { ParentContextType } from 'components/_providers';
import { useParent } from 'hooks';
import type { Student } from 'types';
import EditableAvatar from './EditableAvatar';

// ConfirmDeletionDialog component prop types
interface ConfirmDeletionDialogProps
  extends Pick<EditableControlsProps, 'student' | 'toast'> {
  isOpen: boolean;
  onClose: VoidFunction;
  btnRef: RefObject<HTMLElement>;
}

// EditableControls component prop types
interface EditableControlsProps
  extends Pick<EditableProps, 'onSubmit' | 'onCancel'> {
  isEditing: boolean;
  onRequestEdit: VoidFunction;
  student: Student;
  isUpdating: boolean;
  toast: (props: useToastOptions) => void;
}

// StudentAvatarComponent component prop types
interface StudentAvatarComponentProps
  extends Pick<StudentAvatarsContainerProps, 'setActiveStudent' | 'editable'> {
  student: Student;
}

// StudentAvatarsContainer component prop types
interface StudentAvatarsContainerProps {
  students: ParentContextType['profile']['students']['all'];
  setActiveStudent: ParentContextType['setActiveStudent'];
  editable: boolean;
}

/**
 * Alert dialog to confirm student deletion request
 */
const ConfirmDeletionDialog: FC<ConfirmDeletionDialogProps> = ({
  isOpen,
  onClose,
  btnRef,
  student,
  toast,
}: ConfirmDeletionDialogProps) => {
  const {
    profile: { students },
  } = useParent()!;

  const cancelRef = useRef<HTMLElement | null>(null);

  const [handleDelete, { status }] = useMutation(
    (): Promise<void> => API.delete(`/students/${student.id}`),
    {
      onSuccess: (): void => {
        if (window.localStorage.getItem('activeStudent') === students.active) {
          window.localStorage.removeItem('activeStudent');
        }
        window.location.reload();
      },
      onError: (): void => {
        toast({
          status: 'error',
          description: 'Could not delete student.',
        });
      },
    }
  );

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      finalFocusRef={btnRef}
      onClose={onClose}
      preserveScrollBarGap
      isCentered
      size={['full', 'md']}
    >
      <AlertDialogOverlay />
      <AlertDialogContent rounded={[0, 8]} p={4} color="teal.500">
        <AlertDialogHeader
          fontSize="xl"
          fontWeight="bold"
          fontFamily="heading"
          textTransform="uppercase"
        >
          Delete {student.username}
        </AlertDialogHeader>

        <AlertDialogBody>
          <Text>Are you sure? This action cannot be undone.</Text>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button
            variantColor="orange"
            variant="outline"
            rounded={8}
            fontFamily="heading"
            textTransform="uppercase"
            w="88px"
            ref={cancelRef}
            onClick={onClose}
          >
            Cancel
          </Button>
          <EinsteinButton
            variantColor="red"
            onClick={() => handleDelete()}
            ml={3}
            w="88px"
            isLoading={status === 'loading'}
            loadingText="Deleting..."
          >
            Delete
          </EinsteinButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

/**
 * Username editable input control buttons
 */
const EditableControls: FC<EditableControlsProps> = ({
  isEditing,
  onSubmit,
  onCancel,
  onRequestEdit,
  student,
  isUpdating,
  toast,
}: EditableControlsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLElement | null>(null);

  return isEditing || isUpdating ? (
    <ButtonGroup justifyContent="center" size="sm" mt={2} d="block">
      <IconButton
        variant="ghost"
        aria-label="save changes"
        icon="check"
        onClick={onSubmit as any}
        isLoading={isUpdating}
      />
      <IconButton
        variant="ghost"
        aria-label="cancel change"
        icon="close"
        onClick={onCancel as any}
        isDisabled={isUpdating}
      />
    </ButtonGroup>
  ) : (
    <>
      <Flex justifyContent="center" mt={2}>
        <Tooltip
          aria-label="edit"
          label="Edit username"
          placement="bottom"
          top={0}
        >
          <IconButton
            variant="ghost"
            aria-label="edit"
            size="sm"
            icon="edit"
            onClick={onRequestEdit}
          />
        </Tooltip>
        <Tooltip
          aria-label="delete"
          label="Delete student"
          placement="bottom"
          top={0}
        >
          <IconButton
            variant="ghost"
            aria-label="delete"
            size="sm"
            icon="delete"
            ref={btnRef}
            onClick={onOpen}
          />
        </Tooltip>
      </Flex>
      <ConfirmDeletionDialog
        isOpen={isOpen}
        onClose={onClose}
        btnRef={btnRef}
        student={student}
        toast={toast}
      />
    </>
  );
};

// StudentAvatarComponent component prop types
interface StudentAvatarComponentProps
  extends Pick<StudentAvatarsContainerProps, 'setActiveStudent' | 'editable'> {
  student: Student;
}

/**
 * Individual student avatar, features editable mode
 */
const StudentAvatarComponent: FC<StudentAvatarComponentProps> = ({
  student,
  editable,
  setActiveStudent,
}: StudentAvatarComponentProps) => {
  const { id, picture, username } = student;
  const toast = useToast();

  const [newUsername, setNewUsername] = useState<string>(username);

  const [
    handleUpdateUsername,
    { status: handleUpdateUsernameStatus },
  ] = useMutation(
    (): Promise<void> =>
      API.patch(`/students/${student.id}`, { username: newUsername }),
    {
      onSuccess: () => window.location.reload(),
      onError: () => {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Could not update username.',
          duration: 4500,
          isClosable: true,
        });
        setNewUsername(username);
      },
    }
  );

  const [
    handleUpdateAvatar,
    { status: handleUpdateAvatarStatus },
  ] = useMutation(
    (newPicture: string): Promise<void> =>
      API.patch(`/students/${student.id}`, { picture: newPicture }),
    {
      onSuccess: () => window.location.reload(),
      onError: () =>
        toast({
          title: 'Error',
          status: 'error',
          description: 'Could not update avatar.',
          duration: 4500,
          isClosable: true,
        }),
    }
  );

  return (
    <PseudoBox
      d="flex"
      flexDirection="column"
      alignItems="center"
      w={['150px', null, '200px']}
      key={id}
      _hover={!editable ? { bg: 'gray.100' } : undefined}
      _active={!editable ? { bg: 'gray.200' } : undefined}
      rounded={8}
      py={4}
      cursor={!editable ? 'pointer' : undefined}
      tabIndex={!editable ? 0 : undefined}
      onClick={!editable ? () => setActiveStudent(id) : undefined}
    >
      <EditableAvatar
        src={picture}
        student={student}
        editable={editable}
        handleEdit={handleUpdateAvatar}
      />
      {editable ? (
        <Editable
          mt={4}
          value={newUsername}
          isPreviewFocusable={false}
          submitOnBlur={false}
          textAlign="center"
          fontSize="lg"
          maxW="inherit"
          onChange={(input) => setNewUsername(input)}
          onSubmit={() => {
            if (newUsername === username) return;

            if (newUsername.length === 0) {
              toast({
                status: 'warning',
                description: 'Username is required.',
              });
              setNewUsername(username);
              return;
            }

            handleUpdateUsername();
          }}
        >
          {(props: {
            isEditing: boolean;
            onSubmit: VoidFunction;
            onCancel: VoidFunction;
            onRequestEdit: VoidFunction;
          }): ReactElement => (
            <>
              <EditablePreview />
              <EditableInput onBlur={undefined} />
              <EditableControls
                {...props}
                student={student}
                isUpdating={
                  handleUpdateAvatarStatus === 'loading' ||
                  handleUpdateUsernameStatus === 'loading'
                }
                toast={toast}
              />
            </>
          )}
        </Editable>
      ) : (
        <Text fontWeight="bold" fontSize="lg" textAlign="center" mt={4}>
          {username}
        </Text>
      )}
    </PseudoBox>
  );
};

/**
 * Wraps student avatar select/edit components
 */
const StudentAvatars: FC<StudentAvatarsContainerProps> = ({
  students,
  setActiveStudent,
  editable,
}: StudentAvatarsContainerProps) => (
  <Stack isInline spacing={0} flexWrap="wrap" justifyContent="center">
    {students.map((student) => (
      <StudentAvatarComponent
        key={student.id}
        student={student}
        setActiveStudent={setActiveStudent}
        editable={editable}
      />
    ))}
    {editable && (
      <Flex py={4} w={['150px', null, '200px']} justifyContent="center">
        <IconButtonGatsbyLink
          to="/parent/profile/create"
          aria-label="create student account"
          w="8rem"
          h="8rem"
          rounded="100%"
          variant="outline"
          icon={() => <Icon name="add" fontSize="2rem" />}
        />
      </Flex>
    )}
  </Stack>
);

export default StudentAvatars;
