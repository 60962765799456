import React from 'react';
import type { FC, ReactElement } from 'react';

import { Flex, List, ListIcon, ListItem } from '@chakra-ui/core';

import MissileFatSvg from 'assets/svgs/missile-fat.svg';
import MissileThinSvg from 'assets/svgs/missile-thin.svg';
import RocketLargeSvg from 'assets/svgs/rocket-large.svg';
import RocketSmallSvg from 'assets/svgs/rocket-small.svg';
import UfoFlatSvg from 'assets/svgs/ufo-flat.svg';
import UfoLeggedSvg from 'assets/svgs/ufo-legged.svg';
import type { Plan } from 'types';

export interface PlanElement extends Plan {
  picture: ReactElement;
  features: ReactElement;
}

export const missileThin = (
  <Flex align="center" w="75px" h="175px" maxW="100%">
    <MissileThinSvg />
  </Flex>
);

export const missileFat = (
  <Flex align="center" w="100px" h="175px" maxW="100%">
    <MissileFatSvg />
  </Flex>
);

export const rocketSmall = (
  <Flex align="center" w="75px" h="175px" maxW="100%">
    <RocketSmallSvg />
  </Flex>
);

export const rocketLarge = (
  <Flex align="center" w="100px" h="175px" maxW="100%">
    <RocketLargeSvg />
  </Flex>
);

export const ufoFlat = (
  <Flex align="center" w="165px" h="175px" maxW="100%">
    <UfoFlatSvg />
  </Flex>
);

export const ufoLegged = (
  <Flex align="center" w="165px" h="175px" maxW="100%">
    <UfoLeggedSvg />
  </Flex>
);

interface FeaturesListProps {
  features: string[];
}

const FeaturesList: FC<FeaturesListProps> = ({
  features,
}: FeaturesListProps) => (
  <List spacing={2}>
    {features.map((feature) => (
      <ListItem key={feature}>
        <ListIcon icon="check-circle" color="orange.500" />
        {feature}
      </ListItem>
    ))}
  </List>
);

const plans: {
  spanish: PlanElement[];
  spanishOneOnOne: PlanElement[];
  literacy: {
    [key in 'Einstein Studios' | 'PRIDE']: PlanElement[];
  };
  admin: PlanElement[];
} = {
  // Spanish group plans
  spanish: [
    {
      id:
        process.env.NODE_ENV === 'production'
          ? 'plan_GL3MiRUN9xENSR'
          : 'plan_GlWnSFPmiUI7BP',
      name: 'Gold',
      price: 99,
      classesPerWeek: 1,
      isOneOnOne: false,
      subject: 'Spanish',
      interval: 'month',
      intervalCount: 1,
      picture: rocketSmall,
      features: (
        <FeaturesList
          features={[
            'VR headset provided',
            'Weekly class with real teacher',
            'Personalized feedback after every class',
            'Up to four students per class',
          ]}
        />
      ),
    },
    {
      id:
        process.env.NODE_ENV === 'production'
          ? 'plan_GL3OSaj8iyPl5b'
          : 'plan_GiB00uVWNkcOVA',
      name: 'Platinum',
      price: 189,
      classesPerWeek: 2,
      isOneOnOne: false,
      subject: 'Spanish',
      interval: 'month',
      intervalCount: 1,
      picture: rocketLarge,
      features: (
        <FeaturesList
          features={[
            'VR headset provided',
            'Two weekly classes with real teachers',
            'Personalized feedback after every class',
            'Up to four students per class',
          ]}
        />
      ),
    },
  ],

  // Spanish one-on-one plans
  spanishOneOnOne: [
    {
      id:
        process.env.NODE_ENV === 'production'
          ? 'plan_HDyzjwnDPdBgRt'
          : 'plan_HDyj5MmGvOWCuT',
      name: 'Meteor Quartz',
      price: 199,
      classesPerWeek: 1,
      isOneOnOne: true,
      subject: 'Spanish',
      interval: 'month',
      intervalCount: 1,
      picture: missileThin,
      features: (
        <FeaturesList
          features={[
            'VR headset provided',
            'Weekly class with real teacher',
            'Personalized feedback after every class',
            'Dedicated 1-on-1 tutoring',
          ]}
        />
      ),
    },
    {
      id:
        process.env.NODE_ENV === 'production'
          ? 'plan_HE1xeDKkaSfW5F'
          : 'plan_HDylgYev3wqTNN',
      name: 'Moon Stone',
      price: 389,
      classesPerWeek: 2,
      isOneOnOne: true,
      subject: 'Spanish',
      interval: 'month',
      intervalCount: 1,
      picture: missileFat,
      features: (
        <FeaturesList
          features={[
            'VR headset provided',
            'Two weekly classes with real teachers',
            'Personalized feedback after every class',
            'Dedicated 1-on-1 tutoring',
          ]}
        />
      ),
    },
  ],

  literacy: {
    // Einstein Studios literacy plans
    'Einstein Studios': [
      {
        id:
          process.env.NODE_ENV === 'production'
            ? 'plan_Gq4WOcTf5Ys1PT'
            : 'plan_Gqp0W2FaC4i6Hx',
        name: 'Gold',
        price: 389,
        classesPerWeek: 1,
        isOneOnOne: true,
        subject: 'Literacy',
        interval: 'month',
        intervalCount: 1,
        picture: ufoLegged,
        features: (
          <FeaturesList
            features={[
              '1-on-1 structured literacy',
              'VR headset provided',
              'Weekly class with OG tutor',
              'Personalized feedback after every class',
            ]}
          />
        ),
      },
      {
        id:
          process.env.NODE_ENV === 'production'
            ? 'plan_GoZ7dEq7rC4EYW'
            : 'plan_Gqozmoi3DR96ia',
        name: 'Platinum',
        price: 779,
        classesPerWeek: 2,
        isOneOnOne: true,
        subject: 'Literacy',
        interval: 'month',
        intervalCount: 1,
        picture: ufoFlat,
        features: (
          <FeaturesList
            features={[
              '1-on-1 structured literacy',
              'VR headset provided',
              'Two weekly classes with OG tutors',
              'Personalized feedback after every class',
            ]}
          />
        ),
      },
    ],

    // PRIDE literacy plans
    PRIDE: [
      {
        id:
          process.env.NODE_ENV === 'production'
            ? 'plan_H22kEvTjvBcbQV'
            : 'plan_H22nK35F9RrcYu',
        name: 'Silver',
        price: 349,
        classesPerWeek: 1,
        isOneOnOne: true,
        subject: 'Literacy',
        interval: 'month',
        intervalCount: 1,
        picture: ufoLegged,
        features: (
          <FeaturesList
            features={[
              '1-on-1 structured literacy',
              'VR headset provided',
              'Weekly class with PRIDE specialist',
              'Personalized feedback after every class',
            ]}
          />
        ),
      },
      {
        id:
          process.env.NODE_ENV === 'production'
            ? 'plan_H22lg63EE7tcCJ'
            : 'plan_H22o91Mh8ZEual',
        name: 'Gold',
        price: 649,
        classesPerWeek: 2,
        isOneOnOne: true,
        subject: 'Literacy',
        interval: 'month',
        intervalCount: 1,
        picture: ufoFlat,
        features: (
          <FeaturesList
            features={[
              '1-on-1 structured literacy',
              'VR headset provided',
              'Two weekly classes with PRIDE specialist',
              'Personalized feedback after every class',
            ]}
          />
        ),
      },
    ],
  },

  // Internal administrator plans
  admin: [
    {
      id:
        process.env.NODE_ENV === 'production'
          ? 'plan_GuAisQDGMGxMnp'
          : 'plan_GuAngFcxeYBgvU',
      name: 'Admin',
      price: 0,
      classesPerWeek: 2,
      isOneOnOne: false,
      subject: 'Spanish',
      interval: 'month',
      intervalCount: 1,
      picture: rocketLarge,
      features: (
        <FeaturesList
          features={[
            'VR headset provided',
            'Two weekly classes with real teachers',
            'Personalized feedback after every class',
            'Up to four students per class',
          ]}
        />
      ),
    },
    {
      id:
        process.env.NODE_ENV === 'production'
          ? 'plan_GuAkYzHVwmLOEw'
          : 'plan_GuAnv4GChqCKbZ',
      name: 'Admin',
      price: 0,
      classesPerWeek: 1,
      isOneOnOne: true,
      subject: 'Literacy',
      interval: 'month',
      intervalCount: 1,
      picture: ufoFlat,
      features: (
        <FeaturesList
          features={[
            '1-on-1 structured literacy',
            'VR headset provided',
            'Weekly class with OG tutor',
            'Personalized feedback after every class',
          ]}
        />
      ),
    },
  ],
};

export default plans;
