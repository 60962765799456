import React from 'react';
import type { FC } from 'react';
import { useQuery } from 'react-query';

import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PseudoBox,
  useDisclosure,
} from '@chakra-ui/core';

import API from 'api';
import { LoadingSpinner } from 'components/_lib';
import type { Student } from 'types';

/**
 * Fetches student avatars data.
 */
const fetchAvatars = async (): Promise<string[]> => {
  try {
    const response = await API.get<string[]>('/students/avatars');
    return response.data;
  } catch {
    return [];
  }
};

// AvatarPicker component prop types
interface AvatarPickerProps
  extends Pick<EditableAvatarProps, 'student' | 'handleEdit'> {
  isOpen: boolean;
  onClose: VoidFunction;
}

// EditableAvatar component prop editables
interface EditableAvatarProps {
  src?: string;
  student?: Student;
  editable: boolean;
  handleEdit: (newPicture: string) => Promise<void> | void;
}

/**
 * Modal to select a new student avatar
 */
const AvatarPicker: FC<AvatarPickerProps> = ({
  isOpen,
  onClose,
  student,
  handleEdit,
}: AvatarPickerProps) => {
  // Fetch available avatars from API
  const { data: avatars, status } = useQuery('avatars', fetchAvatars);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={['full', 'md']}
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent borderRadius={[0, 8]} color="teal.500" textAlign="center">
        <IconButton
          variant="ghost"
          variantColor="teal"
          aria-label="close"
          icon="close"
          w="min-content"
          alignSelf="flex-start"
          rounded={8}
          mt={2}
          ml={2}
          onClick={onClose}
        />
        <ModalHeader fontFamily="heading" textTransform="uppercase">
          Select New Avatar
        </ModalHeader>
        {status === 'loading' ? (
          <Flex h="200px" align="center" justify="center">
            <LoadingSpinner />
          </Flex>
        ) : (
          <Flex
            mb="2.5rem"
            flexWrap="wrap"
            p={4}
            maxH="400px"
            overflowY="scroll"
            justify="center"
          >
            {avatars
              ?.filter((a: string) => a !== student?.picture)
              .map((avatar: string) => (
                <PseudoBox
                  _hover={{ boxShadow: 'sm' }}
                  _active={{ boxShadow: 'lg' }}
                  key={avatar}
                  m={4}
                  cursor="pointer"
                  rounded="100%"
                  onClick={async (): Promise<void> => {
                    await handleEdit(avatar);
                    onClose();
                  }}
                >
                  <Avatar src={avatar} size="xl" showBorder />
                </PseudoBox>
              ))}
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
};

/**
 * Avatar with optional edit circle icon button to pick image
 */
const EditableAvatar: FC<EditableAvatarProps> = ({
  src,
  student,
  editable,
  handleEdit,
}: EditableAvatarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box position="relative">
        <Avatar size="2xl" src={src} showBorder />
        {editable && (
          <IconButton
            aria-label="edit"
            icon="edit"
            variant="solid"
            onClick={onOpen}
            position="absolute"
            rounded="100%"
            size="sm"
            top={1}
            right={1}
          />
        )}
      </Box>
      {editable && (
        <AvatarPicker
          student={student}
          isOpen={isOpen}
          onClose={onClose}
          handleEdit={handleEdit}
        />
      )}
    </>
  );
};

export default EditableAvatar;
