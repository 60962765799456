import React from 'react';
import type { FC } from 'react';

import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/core';
import styled from '@emotion/styled';

import EinsteinStudiosLogoUpright from 'assets/svgs/einstein-studios-logo-upright.svg';
import { Emoji } from 'components/_lib';
import { withLayout, withSEO } from 'components/_hoc';
import { useActiveStudent, useParent } from 'hooks';
import colors from 'theme/colors';

// Bubble component prop types
interface BubbleProps {
  size: string;
  left: string;
  top: string;
}

/**
 * CSS bubble
 */
const Bubble = styled.div<BubbleProps>(
  ({ size, left, top }) => `
  height: ${size};
  width: ${size};
  position: absolute;
  background: #eb8c24;
  left: ${left};
  top: ${top};
  border-radius: 100px;
`
);

/**
 * Orange background box with dots
 */
const OrangeBackground: FC<{}> = () => (
  <Box
    pos="absolute"
    left={0}
    right={0}
    top={['82px', '84px', '86px', '90px']}
    backgroundColor="orange.500"
    h="45vh"
    overflowX="hidden"
    zIndex={0}
  >
    <Bubble size="100px" left="-15px" top="20px" />
    <Bubble size="25px" left="15%" top="100px" />
    <Bubble size="70px" left="5%" top="50%" />
    <Bubble size="100px" left="85%" top="15px" />
    <Bubble size="40px" left="70%" top="35%" />
    <Bubble size="25px" left="90%" top="55%" />
    <Bubble size="25px" left="75%" top="77%" />
  </Box>
);

/**
 * Parent referral link with copy button
 */
const ReferralLink: FC<{}> = () => {
  const { profile } = useParent()!;
  const { onCopy, hasCopied } = useClipboard(profile.referralLink);

  return (
    <Flex>
      <Input isReadOnly value={profile.referralLink} />
      <Button onClick={onCopy} ml={2} px={8}>
        {hasCopied ? 'Copied!' : 'Copy'}
      </Button>
    </Flex>
  );
};

// Orange span
const StyledSpan = styled.span`
  color: ${colors.orange['500']};
`;

/**
 * Box wrapping referral link and description
 */
const ReferralLinkBox: FC<{}> = () => (
  <Stack
    w={['100%', '90%', '80%']}
    backgroundColor="white"
    border="1px solid"
    borderColor="gray.500"
    rounded={8}
    p={8}
    spacing={8}
    color="teal.500"
    zIndex={1}
    align="center"
    justify="center"
    textAlign="center"
  >
    <Box w="75px">
      <EinsteinStudiosLogoUpright />
    </Box>
    <Text w={['100%', '90%', '80%', '70%']}>
      Refer your friend to Einstein Studios. When they subscribe, we will credit
      you <StyledSpan>3 free classes</StyledSpan>! Your friend will receive one
      free class themselves <Emoji symbol="🙂" label="slighty smiling face" />
    </Text>
    <Text fontWeight="bold">Your unique referral link:</Text>
    <ReferralLink />
  </Stack>
);

/**
 * Lays out parent referral link page
 */
const ReferralContainer: FC<{}> = () => {
  // Select active student avatar URL
  const { picture } = useActiveStudent();

  return (
    <>
      <OrangeBackground />
      <Stack spacing={8} color="white" align="center" justify="center" mt={4}>
        <Stack spacing={4} align="center" justify="center" textAlign="center">
          <Avatar size="xl" zIndex={1} src={picture} />
          <>
            <Heading size="md" color="white" zIndex={1}>
              Share your love of Einstein Studios!
            </Heading>
            <Text zIndex={1} w="250px" maxW="80%">
              Help a friend join the Einstein family and get free classes!
            </Text>
          </>
        </Stack>
        <ReferralLinkBox />
      </Stack>
    </>
  );
};

export default withSEO(withLayout(ReferralContainer), {
  title: 'Referral • Parent',
  description:
    'Parent referral link. Refer a friend to Einstein Studios and you will both be credited free classes!',
  pathname: '/parent/referral',
});
