import React, { useState } from 'react';
import type { FC, ReactElement } from 'react';
import { useLocation } from '@reach/router';
import { useQuery } from 'react-query';
import type { QueryFunction } from 'react-query';
import { format, parseISO } from 'date-fns';

import { Avatar, Box, Icon, Skeleton, Stack, Text } from '@chakra-ui/core';

import API from 'api';
import {
  ButtonGatsbyLink,
  EinsteinTable,
  MobileSection,
  MobileSectionTitle,
  MobileSectionFooter,
  MobileSectionGroup,
  MobileSectionItem,
  PaginationButtons,
  Section,
  SectionBody,
  SectionFooter,
  SectionTitle,
} from 'components/_lib';
import type { PastClass, Student } from 'types';

/*
 * === CONSTANTS ===
 */

// Number of classes to display per pagination
const SLICE_SIZE = 5;

// Table column titles
const TABLE_COLUMNS = ['Class Name', 'Teacher', 'Date & Time', 'Feedback'];

/*
 * === HELPERS ===
 */

/**
 * React Query function to fetch student past classes
 */
const fetchPastClasses: QueryFunction<
  PastClass[],
  [string, { studentId: string; limit?: number }]
> = async (_, { studentId, limit }): Promise<PastClass[]> => {
  try {
    const response = await API.get(`/students/${studentId}/classes`, {
      params: { limit, type: 'past' },
    });
    return response.data;
  } catch {
    return [];
  }
};

/**
 * Generates an array of data for a past student class row in EinsteinTable.
 *
 * @param pastClass - Class to build data for for.
 *
 * @returns - Array of table data for given class.
 */
const buildPastClassTableData = (
  pastClass: PastClass
): (string | ReactElement)[] => [
  <Stack
    key={`${pastClass.id}-subject`}
    isInline
    align="center"
    justify="center"
  >
    <Avatar size="sm" src={pastClass.subject.picture} />
    <Box>
      <Text fontWeight="bold">
        {pastClass.isTrial ? 'Assessment' : `${pastClass.subject.name} Class`}
      </Text>
      <Text d="flex" alignItems="center">
        <Icon name="time" mr={1} /> {pastClass.durationInMinutes} min.
      </Text>
    </Box>
  </Stack>,
  <Stack
    key={`${pastClass.id}-teacher`}
    isInline
    align="center"
    justify="center"
  >
    <Avatar size="sm" src={pastClass.teacher.picture || undefined} />
    <Text>{pastClass.teacher.fullName}</Text>
  </Stack>,
  format(parseISO(pastClass.datetime), 'LLL d, p'),
  pastClass.wasAbsent === true ? (
    'Absent'
  ) : pastClass.feedbackId ? (
    <ButtonGatsbyLink
      variantColor="orange"
      textTransform="uppercase"
      fontFamily="heading"
      size="sm"
      rounded={8}
      rightIcon="chat"
      to={`/parent/home/history/${pastClass.id}`}
    >
      Feedback
    </ButtonGatsbyLink>
  ) : (
    'Not Available'
  ),
];

/*
 * === COMPONENTS ===
 */

/**
 * Renders an upcoming class entry on mobile
 */
const MobilePastClass: FC<PastClass> = ({
  id,
  teacher,
  datetime,
  durationInMinutes,
  subject,
  isTrial,
  feedbackId,
  wasAbsent,
}: PastClass) => (
  <MobileSectionItem key={id}>
    <Stack spacing={4} alignItems="center" isInline shouldWrapChildren>
      <Avatar src={teacher.picture || undefined} />
      <Box>
        <Text fontWeight="bold">
          {isTrial ? 'Assessment' : `${subject.name} Class`}
        </Text>
        <Text w="140px">{format(parseISO(datetime), 'LLL d, p')}</Text>
        <Text d="flex" alignItems="center">
          <Icon name="time" mr={1} /> {durationInMinutes} min.
        </Text>
      </Box>
    </Stack>
    {feedbackId && !wasAbsent && (
      <ButtonGatsbyLink
        variantColor="orange"
        textTransform="uppercase"
        fontFamily="heading"
        size="sm"
        rounded={8}
        rightIcon="chat"
        to={`/parent/home/history/${id}`}
      >
        Feedback
      </ButtonGatsbyLink>
    )}
  </MobileSectionItem>
);

// HistoryTable component prop types
interface HistoryTableProps {
  student: Pick<Student, 'id' | 'username' | 'picture' | 'level' | 'stars'>;
  limit?: number;
}

/**
 * Parent class history table
 */
const HistoryTable: FC<HistoryTableProps> = ({
  student,
  limit,
}: HistoryTableProps) => {
  const { pathname } = useLocation();

  const { data: pastClasses, status } = useQuery(
    ['pastClasses', { studentId: student.id, limit }],
    fetchPastClasses
  );

  const [current, setCurrent] = useState<number>(0);

  const classSlice = pastClasses?.slice(
    current,
    Math.min(pastClasses.length, current + SLICE_SIZE)
  );

  const paginationButtons = (
    <PaginationButtons
      current={current}
      setCurrent={setCurrent}
      count={!pastClasses ? 0 : pastClasses.length}
      pageSize={SLICE_SIZE}
    />
  );

  const noClasses = (
    <Stack spacing={4} align="center">
      <Text fontWeight="bold">{student.username} has no past classes.</Text>
    </Stack>
  );

  return (
    <>
      {/* Mobile version */}
      <MobileSection>
        <MobileSectionTitle
          backTo={pathname !== '/parent/home' ? '/parent/home' : undefined}
        >
          History
        </MobileSectionTitle>
        <Skeleton
          h={status === 'loading' ? '200px' : 'auto'}
          w="100%"
          roundedBottom={8}
          isLoaded={status !== 'loading'}
        >
          {pastClasses?.length === 0 ? (
            <MobileSectionGroup p={8}>{noClasses}</MobileSectionGroup>
          ) : (
            <MobileSectionGroup>
              {classSlice?.map((pastClass) => (
                <MobilePastClass key={pastClass.id} {...pastClass} />
              ))}
            </MobileSectionGroup>
          )}
          {pathname === '/parent/home' &&
          (pastClasses?.length || 0) > SLICE_SIZE ? (
            <MobileSectionFooter>
              <ButtonGatsbyLink
                variant="link"
                rightIcon="chevron-right"
                variantColor="teal"
                to="/parent/home/history"
              >
                See More
              </ButtonGatsbyLink>
            </MobileSectionFooter>
          ) : pathname === '/parent/home/history' ? (
            <MobileSectionFooter>{paginationButtons}</MobileSectionFooter>
          ) : null}
        </Skeleton>
      </MobileSection>

      {/* Desktop version */}
      <Section>
        <SectionTitle>History</SectionTitle>
        <Skeleton
          w="90%"
          d="flex"
          flexDir="column"
          alignItems="center"
          h={status === 'loading' ? '150px' : 'auto'}
          rounded={8}
          isLoaded={status !== 'loading'}
        >
          <SectionBody w="100%">
            {pastClasses?.length === 0 ? (
              noClasses
            ) : (
              <EinsteinTable
                columns={TABLE_COLUMNS}
                data={
                  classSlice?.map((pastClass) =>
                    buildPastClassTableData(pastClass)
                  ) || []
                }
              />
            )}
          </SectionBody>
          {pathname === '/parent/home' &&
          (pastClasses?.length || 0) > SLICE_SIZE ? (
            <SectionFooter d="flex" mr="5%" alignSelf="flex-end">
              <ButtonGatsbyLink
                variant="outline"
                variantColor="orange"
                rightIcon="chevron-right"
                textTransform="uppercase"
                fontFamily="heading"
                rounded={8}
                to="/parent/home/history"
              >
                See More
              </ButtonGatsbyLink>
            </SectionFooter>
          ) : pathname === '/parent/home/history' ? (
            <SectionFooter d="flex" mr="5%" alignSelf="flex-end">
              {paginationButtons}
            </SectionFooter>
          ) : null}
        </Skeleton>
      </Section>
    </>
  );
};

export default HistoryTable;
