import React from 'react';
import type { FC } from 'react';

import { Box, Heading, Stack } from '@chakra-ui/core';

import { withSEO } from 'components/_hoc';
import { ButtonGatsbyLink } from 'components/_lib';
import { useParent } from 'hooks';

import StudentAvatars from './StudentAvatars';

/**
 * Screen to manage student profiles
 */
const ManageScreen: FC<{}> = () => {
  const {
    profile: { students },
    setActiveStudent,
  } = useParent()!;

  return (
    <Stack
      minH="100vh"
      color="teal.500"
      spacing={8}
      align="center"
      justify="center"
      p={[4, 8, 12, 16]}
    >
      <Heading size="lg" textTransform="uppercase">
        Manage Accounts
      </Heading>
      <Box>
        <StudentAvatars
          students={students.all}
          setActiveStudent={setActiveStudent}
          editable
        />
      </Box>
      <Stack align="center" justify="center">
        <ButtonGatsbyLink w="150px" to="/parent/home" size="lg">
          Done
        </ButtonGatsbyLink>
        <ButtonGatsbyLink
          to="/parent/profile/edit"
          w="150px"
          variant="ghost"
          fontFamily="body"
          textTransform="none"
        >
          Edit profile.
        </ButtonGatsbyLink>
      </Stack>
    </Stack>
  );
};

export default withSEO(ManageScreen, {
  title: 'Manage Accounts • Parent',
  description: 'Manage student accounts on Einstein Studios.',
  pathname: '/parent/profile/manage',
});
