import React from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';

import { Avatar, Heading, Stack } from '@chakra-ui/core';

import { useActiveStudent } from 'hooks';
import UnavailabilityForm from './UnavailabilityForm';

/**
 * Lays out parent unavailabilty page
 */
const UnavailabilityContainer: FC<{}> = () => {
  const location = useLocation();
  const student = useActiveStudent();

  return (
    <Stack
      spacing={16}
      align="center"
      justify="center"
      color="teal.500"
      bg={['#fff', null, 'none']}
      py={[4, 8, 0]}
      rounded={[8, null, 0]}
    >
      <Stack
        spacing={8}
        isInline
        align="center"
        justify="space-around"
        textAlign="center"
      >
        <Avatar size="2xl" src={student.picture || undefined} />
        <Heading size="lg" textTransform="uppercase">
          {student.username}
        </Heading>
      </Stack>
      <UnavailabilityForm student={student} location={location} />
    </Stack>
  );
};

export default UnavailabilityContainer;
