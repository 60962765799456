import React, { useState } from 'react';
import type { FC } from 'react';
import { useFormik } from 'formik';

import {
  Box,
  FormControl,
  FormErrorMessage,
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/core';
import { IoMdPricetag } from 'react-icons/io';

import API from 'api';
import { EinsteinButton, EinsteinInput, ErrorMessage } from 'components/_lib';
import type { Coupon, ErrorState } from 'types';

// CouponBody Formik form value types
interface CouponBodyFormValues {
  name: string;
}

// CouponBody component prop types
interface CouponBodyProps {
  selectCoupon: (c: Coupon) => void;
  coupon?: Coupon;
}

/**
 * Modal body for coupon input
 */
const CouponBody: FC<CouponBodyProps> = ({
  selectCoupon,
  coupon,
}: CouponBodyProps) => {
  // Payment errors
  const [requestError, setRequestError] = useState<ErrorState>({
    isActive: false,
    message: '',
  });

  // Form state and handlers
  const formik = useFormik<CouponBodyFormValues>({
    initialValues: {
      name: coupon?.name || '',
    },
    validate: ({ name }) => (!name ? { name: 'Cannot be empty.' } : {}),
    onSubmit: async ({ name }) => {
      try {
        const response = await API.get(`/coupons/${name}`);
        if (requestError.isActive) {
          setRequestError({
            isActive: false,
            message: '',
          });
        }
        selectCoupon(response.data);
      } catch (error) {
        setRequestError({
          isActive: true,
          message:
            error.response.status === 404
              ? 'Invalid coupon.'
              : 'Could not verify coupon.',
        });
      }
    },
  });

  return (
    <Stack spacing={8} w="100%" align="center">
      <Box w="100%">
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={8} align="center">
            <FormControl isInvalid={!formik.isValid} w="70%">
              <InputGroup>
                <InputLeftElement>
                  <Box as={IoMdPricetag} ml={1} size="18px" color="teal.500" />
                </InputLeftElement>
                <EinsteinInput
                  id="name"
                  name="name"
                  pl="2.5rem"
                  placeholder="Coupon Code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {coupon && (
                  <InputRightElement>
                    <Icon name="check" color="green.500" />
                  </InputRightElement>
                )}
                {requestError.isActive && (
                  <InputRightElement>
                    <Icon name="close" color="red.500" />
                  </InputRightElement>
                )}
              </InputGroup>
              <FormErrorMessage>
                {formik.touched.name && formik.errors.name}
              </FormErrorMessage>
              {coupon && (
                <Text color="green.500" textAlign="center" mt={2}>
                  ${coupon.amountOff.toFixed(2)} off for {coupon.duration}
                </Text>
              )}
            </FormControl>
            <EinsteinButton
              variant="outline"
              size="lg"
              minW="132px"
              type="submit"
              isLoading={formik.isSubmitting}
              loadingText="Verifying..."
            >
              Submit
            </EinsteinButton>
            <ErrorMessage isActive={requestError.isActive}>
              {requestError.message}
            </ErrorMessage>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default CouponBody;
