import React from 'react';
import type { FC } from 'react';

import { EinsteinRouter, Route } from 'components/_lib';
import FeedbackPage from './FeedbackPage';
import HistoryPage from './HistoryPage';
import RootPage from './RootPage';

/**
 * Parent home page sub-router. Routes to:
 *   /parent/home - Root home page
 *   /parent/home/history - Full class history
 *   /parent/home/history/:classId - Class feedback page
 */
const HomeRouter: FC<{}> = () => (
  <EinsteinRouter>
    <Route path="/" component={RootPage} />
    <Route path="/history" component={HistoryPage} />
    <Route path="/history/:classId" component={FeedbackPage} />
  </EinsteinRouter>
);

export default HomeRouter;
