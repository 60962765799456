import React from 'react';
import type { FC } from 'react';
import { Flex, Stack, Text } from '@chakra-ui/core';
import ValidationCircleSvg from 'assets/svgs/validation-circle.svg';
import { ButtonChakraLink, Emoji } from 'components/_lib';
import type { ConfirmationModalContainerProps } from './types';

// ThankYouBody component prop types
type ThankYouBodyProps = Pick<ConfirmationModalContainerProps, 'type'>;

/**
 * Thank you for booking class note
 */
const ThankYouBody: FC<ThankYouBodyProps> = ({ type }: ThankYouBodyProps) => (
  <Stack spacing={8} color="teal.500" alignItems="center" p={8}>
    <Stack spacing={4} alignItems="center">
      <Flex w="6rem" h="6rem">
        <ValidationCircleSvg />
      </Flex>
      <Text fontWeight="bold">
        {type === 'change'
          ? 'Booking successfully changed'
          : 'Class confirmed, thank you!'}
      </Text>
    </Stack>
    {type === 'change' ? (
      <Text textAlign="center">
        Your booking has been offically changed. Your student has been
        unattended from any classes scheduled by the previous booking.
      </Text>
    ) : (
      <Text textAlign="center">
        Your {type === 'recurring' && 'recurring '}class has officially been{' '}
        booked! We have sent you an email with further details. Thank you, and
        we&apos;ll see you in class!{' '}
        <Emoji label="grinning face with smiling eyes" symbol="😁" />
      </Text>
    )}
    <ButtonChakraLink
      size="lg"
      aria-label="return home"
      variantColor="orange"
      variant="outline"
      href={`${process.env.WEB_PLATFORM_URL}/parent/home`}
    >
      Return Home
    </ButtonChakraLink>
  </Stack>
);

export default ThankYouBody;
