import React from 'react';
import type { FC } from 'react';
import { navigate } from 'gatsby';

import { Box, Heading, Stack } from '@chakra-ui/core';

import { withSEO } from 'components/_hoc';
import { EinsteinButton } from 'components/_lib';
import { useParent } from 'hooks';
import StudentAvatars from './StudentAvatars';

/**
 * Screen to select active student
 */
const SelectScreen: FC<{}> = () => {
  const {
    profile: { students },
    setActiveStudent,
  } = useParent()!;

  /**
   * Navigate to account management page
   */
  const manageAccounts: VoidFunction = () => {
    navigate('/parent/profile/manage');
  };

  return (
    <Stack
      minH="100vh"
      color="teal.500"
      spacing={8}
      align="center"
      justify="center"
      p={[4, 8, 12, 16]}
    >
      <Heading size="lg" textTransform="uppercase">
        Who Am I?
      </Heading>
      <Box>
        <StudentAvatars
          students={students.all}
          setActiveStudent={setActiveStudent}
          editable={false}
        />
      </Box>
      <EinsteinButton size="lg" onClick={manageAccounts}>
        Manage Accounts
      </EinsteinButton>
    </Stack>
  );
};

export default withSEO(SelectScreen, {
  title: 'Who Am I • Parent',
  description:
    'Select the active student account to view in the Einstein Studios parent dashboard.',
  pathname: '/parent/profile/select',
});
