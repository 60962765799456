import React, { useState } from 'react';
import type { FC } from 'react';
import { useMutation } from 'react-query';

import { Box, Flex, Link, Stack, Text } from '@chakra-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import API from 'api';
import PoweredByStripeSvg from 'assets/svgs/powered-by-stripe.svg';
import { EinsteinButton, ErrorMessage } from 'components/_lib';
import theme from 'theme';
import type { ErrorState } from 'types';
import { ActionTypes, SubscriptionActionsModalContainerProps } from './types';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_API_KEY!);

// PaymentMethodBody component prop types
interface PaymentMethodBodyProps
  extends Pick<SubscriptionActionsModalContainerProps, 'type'> {
  plan?: string;
  customer?: string;
  name: string;
  email: string;
  navigateToThankYou: VoidFunction;
  coupon?: string;
}

/**
 * Modal body to create Stripe payment method
 */
const PaymentMethodBody: FC<PaymentMethodBodyProps> = ({
  type,
  plan,
  customer,
  name,
  email,
  coupon,
  navigateToThankYou,
}: PaymentMethodBodyProps) => {
  const stripe = useStripe();
  const elements = useElements();

  // Payment errors
  const [requestError, setRequestError] = useState<ErrorState>({
    isActive: false,
    message: '',
  });

  const [mutate, { status }] = useMutation(
    async (): Promise<void> => {
      const card = elements?.getElement(CardElement);
      if (!stripe || !card) throw new Error('Loading Stripe...');

      const { token, error } = await stripe.createToken(card, {
        name,
        currency: 'usd',
      });

      if (error || !token) {
        throw new Error(error?.message || 'Stripe verification failed');
      }

      if (type === ActionTypes.purchase) {
        await API.post('/subscriptions', {
          name,
          email,
          plan,
          source: token.id,
          coupon,
        });
      } else if (type === ActionTypes.changePaymentMethod) {
        await API.put(`/customers/${customer!}/source`, {
          source: token.id,
        });
      }
    },
    {
      onSuccess: () => {
        setRequestError({ isActive: false, message: '' });
        navigateToThankYou();
      },
      onError: (error) => {
        setRequestError({
          isActive: true,
          message: (error as any)?.message || 'Could not process cancellation',
        });
      },
    }
  );

  return (
    <Stack spacing={8} w="100%" align="center">
      <Stack
        spacing={4}
        w="100%"
        backgroundColor="#f6f9fc"
        boxShadow="lg"
        textAlign="center"
        align="center"
        justify="center"
        rounded={8}
        p={4}
      >
        <Text fontFamily={'"Segoe UI", sans-serif'} color="#32325d">
          {type === ActionTypes.purchase ? 'Pay with card:' : 'Enter new card:'}
        </Text>
        <Box w="100%" bg="white" p={4} borderRadius={4}>
          <CardElement
            options={{
              style: {
                base: {
                  color: theme.colors.teal['500'],
                  fontFamily: '"Segoe UI", sans-serif',
                  '::placeholder': {
                    color: '#a6bcc1',
                  },
                },
              },
            }}
          />
        </Box>
        <Flex align="center" justify="center">
          <Link isExternal href="https://stripe.com">
            <PoweredByStripeSvg />
          </Link>
        </Flex>
      </Stack>
      <EinsteinButton
        variant="outline"
        size="lg"
        minW="132px"
        onClick={() => mutate()}
        isLoading={status === 'loading'}
        isDisabled={!stripe || !elements}
        loadingText="Submitting..."
      >
        Submit
      </EinsteinButton>
      <ErrorMessage isActive={requestError.isActive} w="80%">
        {requestError.message}
      </ErrorMessage>
    </Stack>
  );
};

// Wraps PaymentMethodBody with Stripe Elements
const PaymentMethodBodyContainer: FC<PaymentMethodBodyProps> = (
  props: PaymentMethodBodyProps
) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodBody {...props} />
    </Elements>
  );
};

export default PaymentMethodBodyContainer;
