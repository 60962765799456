import {
  addWeeks,
  differenceInHours,
  format,
  parseISO,
  setDay,
} from 'date-fns';
import { convertToLocalTime, convertToTimeZone } from 'date-fns-timezone';
import querystring from 'querystring';

import { WeekdaysEnum } from 'data';
import type { Availability, Teacher } from 'types';

/**
 * Gets the local time of a zoned availability start time.
 *
 * @param startTime - Availability class start time.
 * @param timeZone - Timezone that availability is relative to.
 *
 * @returns - The zoned availablity start time in local time.
 */
export const availabilityTimeToLocalTime = (
  startTime: Availability['startTime'],
  timeZone: Teacher['timezone']
): string => {
  const zoned = parseISO(`${format(Date.now(), 'yyyy-MM-dd')} ${startTime}`);
  return format(convertToLocalTime(zoned, { timeZone }), 'HH:mm:ss');
};

/**
 * Generate the next local date instance of a given availability.
 *
 * @param dayOfWeek - Availability class day of week.
 * @param startTime - Availability class start time.
 * @param timeZone - Timezone that availability is relative to.
 *
 * @returns - The next instance of the given weekday (in local time).
 */
export const getNextInstanceOfAvailability = (
  dayOfWeek: Availability['dayOfWeek'],
  startTime: Availability['startTime'],
  timeZone: Teacher['timezone']
): Date => {
  const dayInNeed = WeekdaysEnum[dayOfWeek];
  const zonedNow = convertToTimeZone(Date.now(), { timeZone });
  const zonedAvail = setDay(
    parseISO(`${format(zonedNow, 'yyyy-MM-dd')} ${startTime}`),
    dayInNeed
  );

  return convertToLocalTime(
    differenceInHours(zonedAvail, zonedNow) < 12
      ? addWeeks(zonedAvail, 1)
      : zonedAvail,
    { timeZone }
  );
};

/**
 * Capitalizes a given string.
 *
 * @param str - The string to capitalize.
 *
 * @returns - The capitalized string.
 */
export const capitalize = (str: string): string =>
  `${str.charAt(0).toUpperCase()}${str.slice(1, str.length).toLowerCase()}`;

/**
 * Parses a variable from a URL search string.
 *
 * @param variable - Name of variable to parse.
 * @param search - URL search string.
 *
 * @returns - Parsed variable value, if exists.
 */
export const parseQueryString = (
  variable: string,
  search: string
): string | undefined => {
  let value = querystring.parse(search)[`?${variable}`];
  if (typeof value !== 'string') {
    value = querystring.parse(search)[variable];
  }

  return typeof value !== 'string' ? undefined : value;
};
