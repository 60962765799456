import React, { memo } from 'react';
import type { FC, ReactElement } from 'react';

import { Box, Button, Flex, Stack, Text } from '@chakra-ui/core';
import { IoMdPricetag } from 'react-icons/io';

import { EinsteinButton } from 'components/_lib';
import type { Coupon } from 'types';
import { ActionTypes, SubscriptionActionsModalContainerProps } from './types';
import type { PlanElement } from '../plans';

// PurchaseBody component prop types
interface PurchaseBodyProps
  extends Pick<SubscriptionActionsModalContainerProps, 'type'> {
  plan: PlanElement;
  coupon?: Coupon;
  navigateToDiscount: VoidFunction;
  handleClick: VoidFunction;
}

/**
 * Modal body for package confirmation
 */
const PurchaseBody: FC<PurchaseBodyProps> = ({
  plan: { name, classesPerWeek, price, interval, intervalCount, picture },
  type,
  coupon,
  navigateToDiscount,
  handleClick,
}: PurchaseBodyProps) => (
  <Stack spacing={8} w="100%" align="center">
    <Flex flexDir="row" align="center" justify="space-evenly" w="100%">
      <Flex w={['125px', '150px', '175px']} justify="center">
        {picture}
      </Flex>
      <Stack spacing={4} textAlign="left" ml={2}>
        <Text fontWeight="bold">{name} Subscription</Text>
        <Text>
          {classesPerWeek} recurring{' '}
          {classesPerWeek === 1 ? 'class' : 'classes'}/week
        </Text>
        <Text color={coupon ? 'green.500' : 'teal.500'}>
          ${coupon ? price - coupon.amountOff : price}/
          {intervalCount === 1 ? interval : `${intervalCount} ${interval}s`}{' '}
          {coupon && `(${coupon.name})`}
        </Text>
        <Button
          variant="link"
          variantColor="teal"
          w="min-content"
          rightIcon={(): ReactElement => (
            <Box as={IoMdPricetag} ml={1} size="18px" color="teal.500" />
          )}
          onClick={navigateToDiscount}
        >
          Coupon code?
        </Button>
      </Stack>
    </Flex>
    <EinsteinButton
      variant="outline"
      minW="132px"
      size="lg"
      onClick={handleClick}
    >
      {type === ActionTypes.purchase ? 'Proceed to Payment' : 'Continue'}
    </EinsteinButton>
  </Stack>
);

export default memo(PurchaseBody);
