import React from 'react';
import type { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';
import Img from 'gatsby-image';
import type { GatsbyImageProps } from 'gatsby-image';

import {
  Box,
  Heading,
  Icon,
  PseudoBox,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/core';
import type { PseudoBoxProps } from '@chakra-ui/core';

import { literacy, spanish } from 'data';
import { withLayout, withSpacing } from 'components/_hoc';
import {
  MobileSection,
  MobileSectionGroup,
  MobileSectionItemLink,
  MobileSectionTitle,
  Section,
  SectionBody,
  SectionTitle,
} from 'components/_lib';
import { useSubjectImages } from 'hooks';

// SubjectMenuOption component prop types
type SubjectMenuOptionProps = Omit<PseudoBoxProps, 'children'> &
  GatsbyLinkProps<HTMLElement> & {
    title: string;
    description: string;
    picture: GatsbyImageProps['fluid'];
    alt: GatsbyImageProps['alt'];
  };

/**
 * Menu option to select a subject
 */
const SubjectMenuOption: FC<SubjectMenuOptionProps> = ({
  title,
  description,
  picture,
  alt,
  ...rest
}: SubjectMenuOptionProps) => (
  <PseudoBox
    as={GatsbyLink as any}
    color="teal.500"
    p={8}
    _hover={{ bg: 'gray.100' }}
    _active={{ bg: 'gray.200' }}
    cursor="pointer"
    rounded={8}
    {...rest}
  >
    <Stack isInline spacing={4} alignItems="center">
      <Img
        style={{
          borderRadius: '100%',
          width: '6rem',
          height: '6rem',
        }}
        fluid={picture}
        alt={alt}
      />
      <Heading fontWeight="bold" size="md" ml={4}>
        {title}
      </Heading>
    </Stack>
    <Text mt={4}>{description}</Text>
  </PseudoBox>
);

/**
 * Mobile menu option to select a subject
 */
const MobileSubjectMenuOption: FC<SubjectMenuOptionProps> = ({
  title,
  description,
  picture,
  alt,
  ...rest
}: SubjectMenuOptionProps) => (
  <MobileSectionItemLink
    _hover={{ bg: 'gray.100' }}
    _active={{ bg: 'gray.200' }}
    _last={{ roundedBottom: 8 }}
    cursor="pointer"
    to={`/parent/classes/${literacy.name.toLowerCase()}`}
    as={GatsbyLink as any}
    {...rest}
  >
    <Stack spacing={4} align="start">
      <Stack spacing={4} isInline alignItems="center">
        <Img
          style={{
            borderRadius: '100%',
            width: '4rem',
            height: '4rem',
          }}
          fluid={picture}
          alt={alt}
        />
        <Heading fontWeight="bold" size="md" ml={4}>
          {title}
        </Heading>
      </Stack>
      <Text>{description}</Text>
    </Stack>
    <Icon name="chevron-right" size="2rem" />
  </MobileSectionItemLink>
);

/**
 * Menu to choose a subject
 */
const SubjectMenu: FC<{}> = () => {
  const data = useSubjectImages();

  return (
    <Box>
      {/* Mobile version */}
      <MobileSection h="min-content">
        <MobileSectionTitle>Choose a Subject</MobileSectionTitle>
        <MobileSectionGroup roundedBottom={8}>
          {[literacy, spanish].map(({ id, name, description }, index) => (
            <MobileSubjectMenuOption
              key={id}
              title={['Structured Literacy', 'Spanish'][index]}
              description={description}
              picture={
                [data.literacyImage, data.spanishImage][index].childImageSharp
                  .fluid
              }
              alt={['stack of books', 'Spanish flag'][index]}
              to={`/parent/classes/${name.toLowerCase()}`}
            />
          ))}
        </MobileSectionGroup>
      </MobileSection>

      {/* Section rendered on large screens */}
      <Section>
        <SectionTitle>Choose a Subject</SectionTitle>
        <SectionBody>
          <SimpleGrid columns={2} spacing={10}>
            {[literacy, spanish].map(({ id, name, description }, index) => (
              <SubjectMenuOption
                key={id}
                title={['Structured Literacy', 'Spanish'][index]}
                description={description}
                picture={
                  [data.literacyImage, data.spanishImage][index].childImageSharp
                    .fluid
                }
                alt={['stack of books', 'Spanish flag'][index]}
                to={`/parent/classes/${name.toLowerCase()}`}
              />
            ))}
          </SimpleGrid>
        </SectionBody>
      </Section>
    </Box>
  );
};

export default withLayout(withSpacing(SubjectMenu));
