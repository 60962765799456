import React from 'react';
import type { FC, ReactElement } from 'react';
import { useQuery } from 'react-query';
import type { QueryFunction } from 'react-query';
import { addMinutes, format, parseISO } from 'date-fns';

import {
  Avatar,
  Box,
  Flex,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import { FaCalendarPlus, FaTrashAlt } from 'react-icons/fa';

import API from 'api';
import {
  ButtonGatsbyLink,
  EinsteinTable,
  IconButtonChakraLink,
  IconButtonGatsbyLink,
  MobileSection,
  MobileSectionFooter,
  MobileSectionGroup,
  MobileSectionItem,
  MobileSectionTitle,
  Section,
  SectionBody,
  SectionFooter,
  SectionTitle,
} from 'components/_lib';
import { useParent } from 'hooks';
import { capitalize } from 'utils';
import type { Student, UpcomingStudentClass } from 'types';

/*
 * === HELPERS ===
 */

/**
 * String formats the corresponding unavailability link for a given class date.
 *
 * @param datetime - Start time of class to create link for.
 * @param durationInMinutes - Duration of class to create link for.
 *
 * @returns - Formatted unavialability link.
 */
const buildUnavailabilityLink = (
  datetime: Date,
  durationInMinutes: number
): string => {
  const start = datetime.toISOString();
  const end = addMinutes(datetime, durationInMinutes).toISOString();

  return `/parent/unavailability?startDatetime=${start}&endDatetime=${end}`;
};

/**
 * String formats the add-to-calendar link for a given class.
 *
 * @param student - Name of student attending class.
 * @param upcomingClass - Class to create link for.
 *
 * @returns - Formatted add-to-calendar link.
 */
const buildAddToCalendarLink = (
  student: string,
  {
    isTrial,
    subject,
    datetime,
    durationInMinutes,
    teacher,
  }: Pick<
    UpcomingStudentClass,
    'subject' | 'datetime' | 'durationInMinutes' | 'isTrial' | 'teacher'
  >
): string => {
  const type = isTrial ? 'assessment' : 'class';
  const title = `Einstein Studios ${subject.name} ${capitalize(type)}`;

  const description = [
    `${student}'s upcoming ${subject.name} ${type} with ${teacher.fullName} (${teacher.primaryEmail}).\n\n`,
    'To prepare, please make sure that:\n',
    '1. Your VR headset is fully charged.\n',
    '2. Your VR headset is updated with the Einstein Studios app.\n',
    '3. You launch the app and log in a few minutes before class.\n\n',
    'Need to cancel? Mark unavailability on your parent dashboard.\n',
    'Questions or concerns? Contact us at albert@einsteinstudios.io.',
  ].join('');

  let link = `${process.env.WEB_PLATFORM_URL}/add-to-calendar`;
  link += `?title=${encodeURIComponent(title)}`;
  link += `&datetime=${datetime}`;
  link += `&duration_in_minutes=${durationInMinutes}`;
  link += `&description=${encodeURIComponent(description)}`;

  return link;
};

/**
 * Generates an array of data for an upcoming student class row in EinsteinTable.
 *
 * @param student - Name of student attending class.
 * @param upcomingClass - Class to build data for for.
 *
 * @returns - Array of table data for given class.
 */
const buildUpcomingClassTableData = (
  student: string,
  upcomingClass: UpcomingStudentClass
): (string | ReactElement)[] => {
  const { id, subject, isTrial, durationInMinutes, teacher } = upcomingClass;
  const datetime = parseISO(upcomingClass.datetime);

  return [
    <Stack key={`${id}-subject`} isInline align="center" justify="center">
      <Avatar size="sm" src={subject.picture} />
      <Box textAlign="left">
        <Text fontWeight="bold">
          {isTrial ? 'Assessment' : `${subject.name} Class`}
        </Text>
        <Text d="flex" alignItems="center">
          <Icon name="time" mr={1} /> {durationInMinutes} min.
        </Text>
      </Box>
    </Stack>,
    <Stack key={`${id}-teacher`} isInline align="center" justify="center">
      <Avatar size="sm" src={teacher.picture || undefined} />
      <Text>{teacher.fullName}</Text>
    </Stack>,
    format(datetime, 'LLL d, p'),
    <Flex flexDir="row" justify="center">
      <Tooltip
        aria-label="unavailable"
        label="Unavailable"
        placement="bottom"
        shouldWrapChildren
        pos="absolute"
        top={0}
        left={0}
      >
        <IconButtonGatsbyLink
          key={`${id}-unavailability`}
          aria-label="unavailability"
          variant="ghost"
          variantColor="teal"
          to={buildUnavailabilityLink(datetime, durationInMinutes)}
          fontWeight="normal"
          icon={FaTrashAlt}
          color="teal.500"
          fontFamily="body"
          textTransform="none"
        />
      </Tooltip>
      <Tooltip
        aria-label="add-to-calendar"
        label="Add to Calendar"
        placement="bottom"
        shouldWrapChildren
        pos="absolute"
        top={0}
        left={0}
      >
        <IconButtonChakraLink
          key={`${id}-add-to-calendar`}
          aria-label="add to calendar"
          variant="ghost"
          variantColor="teal"
          href={buildAddToCalendarLink(student, upcomingClass)}
          isExternal
          target="_blank"
          rel="noopener noreferrer"
          fontWeight="normal"
          icon={FaCalendarPlus}
          color="teal.500"
          fontFamily="body"
          textTransform="none"
        />
      </Tooltip>
    </Flex>,
  ];
};

/**
 * Fetches student upcoming classes
 *
 * @param key - React-Query key
 */
const fetchUpcomingClasses: QueryFunction<
  UpcomingStudentClass[],
  [string, { studentId: string }]
> = async (_, { studentId }) => {
  try {
    const response = await API.get<UpcomingStudentClass[]>(
      `/students/${studentId}/classes`,
      { params: { type: 'upcoming' } }
    );
    return response.data;
  } catch {
    return [];
  }
};

/*
 * === COMPONENTS ===
 */

// MobileUpcomingClass component prop types
type MobileUpcomingClassProps = UpcomingStudentClass & UpcomingTableProps;

/**
 * Renders an upcoming class entry on mobile
 */
const MobileUpcomingClass: FC<MobileUpcomingClassProps> = ({
  student,
  id,
  teacher,
  datetime,
  durationInMinutes,
  subject,
  isTrial,
}: MobileUpcomingClassProps) => {
  const parsed = parseISO(datetime);

  return (
    <MobileSectionItem>
      <Stack spacing={4} alignItems="center" isInline shouldWrapChildren>
        <Avatar size="md" src={teacher.picture || undefined} />
        <Box>
          <Text fontWeight="bold">
            {isTrial ? 'Assessment' : `${subject.name} Class`}
          </Text>
          <Text>{format(parsed, 'LLL d, p')}</Text>
          <Text d="flex" alignItems="center">
            <Icon name="time" mr={1} /> {durationInMinutes} min.
          </Text>
        </Box>
      </Stack>
      <Flex flexDir="row">
        <IconButtonGatsbyLink
          aria-label="unavailability"
          icon="delete"
          variant="ghost"
          to={buildUnavailabilityLink(parsed, durationInMinutes)}
        />
        <IconButtonChakraLink
          key={`${id}-add-to-calendar`}
          aria-label="add to calendar"
          variant="ghost"
          variantColor="teal"
          href={buildAddToCalendarLink(student.username, {
            subject,
            datetime,
            durationInMinutes,
            teacher,
            isTrial,
          })}
          target="_blank"
          rel="noopener noreferrer"
          fontWeight="normal"
          icon={FaCalendarPlus}
          color="teal.500"
          fontFamily="body"
          textTransform="none"
        />
      </Flex>
    </MobileSectionItem>
  );
};

// UpcomingTable component prop types
interface UpcomingTableProps {
  student: Student;
}

/**
 * Student upcoming classes table
 */
const UpcomingTable: FC<UpcomingTableProps> = ({
  student,
}: UpcomingTableProps) => {
  const { profile } = useParent()!;
  const hasBookings =
    [
      ...profile.subjects.Spanish.bookings,
      ...profile.subjects.Literacy.bookings,
    ].length > 0;

  const { data: upcomingClasses, status } = useQuery(
    ['upcomingClasses', { studentId: student.id }],
    fetchUpcomingClasses
  );

  const noClasses = (
    <Stack spacing={4} align="center">
      <Text fontWeight="bold">{student.username} has no upcoming classes.</Text>
      <ButtonGatsbyLink
        to="/parent/classes"
        rounded={8}
        variantColor="orange"
        fontFamily="heading"
        textTransform="uppercase"
        w="min-content"
      >
        Book Classes
      </ButtonGatsbyLink>
    </Stack>
  );

  return (
    <>
      {/* Mobile version */}
      <MobileSection>
        <MobileSectionTitle>Upcoming Classes</MobileSectionTitle>
        <Skeleton
          h={status === 'loading' ? '200px' : 'auto'}
          w="100%"
          roundedBottom={8}
          isLoaded={status !== 'loading'}
        >
          {upcomingClasses?.length === 0 ? (
            <MobileSectionGroup p={8}>{noClasses}</MobileSectionGroup>
          ) : (
            <MobileSectionGroup>
              {upcomingClasses?.map((upcomingClass) => (
                <MobileUpcomingClass
                  key={upcomingClass.id}
                  student={student}
                  {...upcomingClass}
                />
              ))}
            </MobileSectionGroup>
          )}
          {hasBookings && (
            <MobileSectionFooter>
              <ButtonGatsbyLink
                variant="link"
                rightIcon="chevron-right"
                variantColor="teal"
                to="/parent/subscriptions/manage/bookings"
              >
                Manage Bookings
              </ButtonGatsbyLink>
            </MobileSectionFooter>
          )}
        </Skeleton>
      </MobileSection>

      {/* Desktop version */}
      <Section>
        <SectionTitle>Upcoming Classes</SectionTitle>
        <Skeleton
          w="90%"
          d="flex"
          flexDir="column"
          alignItems="center"
          h={status === 'loading' ? '150px' : 'auto'}
          rounded={8}
          isLoaded={status !== 'loading'}
        >
          <SectionBody w="100%">
            {upcomingClasses?.length === 0 ? (
              <Flex h="150px" align="center" justify="center">
                {noClasses}
              </Flex>
            ) : (
              <EinsteinTable
                columns={['Class', 'Teacher', 'Date & Time', 'Actions']}
                data={
                  upcomingClasses?.map((upcomingClass) =>
                    buildUpcomingClassTableData(student.username, upcomingClass)
                  ) || []
                }
              />
            )}
          </SectionBody>
          {hasBookings && (
            <SectionFooter>
              <ButtonGatsbyLink
                variant="solid"
                variantColor="orange"
                textTransform="uppercase"
                fontFamily="heading"
                rounded={8}
                to="/parent/subscriptions/manage/bookings"
              >
                Manage Bookings
              </ButtonGatsbyLink>
            </SectionFooter>
          )}
        </Skeleton>
      </Section>
    </>
  );
};

export default UpcomingTable;
