import React, { useState } from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';
import { format } from 'date-fns';

import {
  Avatar,
  Box,
  Flex,
  Icon,
  IconButton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  PseudoBox,
  useDisclosure,
} from '@chakra-ui/core';

import MoonSmoothWithShadowSvg from 'assets/svgs/moon-smooth-with-shadow.svg';
import MoonJaggedWithShadowSvg from 'assets/svgs/moon-jagged-with-shadow.svg';
import { BreadcrumbNav, ButtonGatsbyLink } from 'components/_lib';
import { useParent } from 'hooks';
import type { Booking, Student } from 'types';
import {
  capitalize,
  getNextInstanceOfAvailability,
  parseQueryString,
} from 'utils';

import DeleteBookingModal from './DeleteBookingModal';
import type { DeleteBookingState } from './types';

// NoSubscription component prop types
interface NoSubscriptionProps {
  subject: string;
}

/**
 * Section rendered when user does not own a subscription for the given subject
 */
const NoSubscription: FC<NoSubscriptionProps> = ({
  subject,
}: NoSubscriptionProps) => (
  <Stack spacing={8} align="center" py={12}>
    <Box w="125px">
      <MoonJaggedWithShadowSvg />
    </Box>
    <Text fontWeight="bold">
      You do not own an active {subject} subscription.
    </Text>
    <ButtonGatsbyLink
      to={`/parent/subscriptions/purchase?subject=${subject}`}
      size="lg"
    >
      Purchase One
    </ButtonGatsbyLink>
  </Stack>
);

// NoBookings component prop types
interface NoBookingsProps {
  subject: string;
  classesPerWeek: number;
}

/**
 * Section rendered when user owns no bookings for given subject
 */
const NoBookings: FC<NoBookingsProps> = ({
  subject,
  classesPerWeek,
}: NoBookingsProps) => (
  <Stack spacing={8} align="center" py={12}>
    <Box w="125px">
      <MoonSmoothWithShadowSvg />
    </Box>
    <Stack spacing={2} align="center">
      <Text fontWeight="bold">
        You do not own any recurring {subject} bookings.
      </Text>
      <Text color="orange.500" d="flex" alignItems="center">
        <Icon name="check-circle" mr={2} />
        You have {classesPerWeek} recurring{' '}
        {classesPerWeek === 1 ? 'booking' : 'bookings'} available.
      </Text>
    </Stack>
    <ButtonGatsbyLink to={`/parent/classes/${subject}/recurring`} size="lg">
      Book Classes
    </ButtonGatsbyLink>
  </Stack>
);

interface BookingManagerProps extends Booking {
  student: Student;
  handleSelect: VoidFunction;
}

/**
 * Component to manage an individual booking
 */
const BookingManager: FC<BookingManagerProps> = ({
  student,
  handleSelect,
  ...booking
}: BookingManagerProps) => {
  const nextClass = getNextInstanceOfAvailability(
    booking.availability.dayOfWeek,
    booking.availability.startTime,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  return (
    <PseudoBox
      display="flex"
      flexDir="column"
      w="100%"
      backgroundColor="white"
      rounded={8}
      roundedTopLeft={0}
      alignItems="center"
      justifyContent="center"
      _notLast={{ borderBottom: '1px solid #979797', roundedBottom: 0 }}
    >
      <Stack
        p={8}
        flexDir="row"
        align="center"
        justify="space-between"
        w={['90%', '85%', '80%', '70%']}
      >
        <Stack
          isInline
          spacing={[2, 4, 6, 8]}
          alignItems="center"
          justify="space-between"
        >
          <Flex flexDir="row">
            <Avatar
              d={['none', null, 'flex']}
              size="lg"
              mr={[null, null, 2, 4]}
              src={booking.teacher.picture || undefined}
            />
            <Flex flexDir="column" textAlign="left" justify="center">
              <Text fontWeight="bold">{booking.teacher.fullName}</Text>
              <Text d="flex" alignItems="center">
                <Icon name="time" mr={1} />{' '}
                {booking.availability.durationInMinutes === 60 ? 50 : 25} min.
                class
              </Text>
            </Flex>
          </Flex>
          <Tooltip
            aria-label="delete"
            label="Delete booking"
            placement="bottom"
            ml={2}
          >
            <IconButton
              variant="ghost"
              aria-label="delete"
              icon={() => <Icon w="1.15rem" h="1.15rem" name="delete" />}
              size="lg"
              onClick={handleSelect}
            />
          </Tooltip>
        </Stack>
        <Flex align="center" justify="center">
          <ButtonGatsbyLink
            to={`/parent/classes/${booking.subject.name.toLowerCase()}/recurring?booking=${
              booking.id
            }`}
            variant="outline"
            size="md"
            aria-label="edit"
            fontFamily="body"
            textTransform="none"
          >
            Change
          </ButtonGatsbyLink>
        </Flex>
      </Stack>

      <Stack
        p={8}
        flexDir="row"
        borderTop="1px solid"
        borderColor="orange.500"
        align="center"
        w={['90%', '85%', '80%', '70%']}
      >
        <Box>
          <Text fontWeight="bold">
            {capitalize(booking.availability.dayOfWeek)}s at{' '}
            {format(nextClass, 'p')}
          </Text>
          <Text>Next class: {format(nextClass, 'Pp')}</Text>
        </Box>
      </Stack>
    </PseudoBox>
  );
};

/**
 * Controls active SubscriptionManager
 */
const BookingManagerTabs: FC<{}> = () => {
  const { profile } = useParent()!;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { search } = useLocation();
  const subjectQuery = parseQueryString('subject', search)?.toLowerCase();

  const defaultTabIndex =
    subjectQuery === 'literacy' ||
    (subjectQuery !== 'spanish' &&
      profile.subjects.Spanish.bookings.length === 0 &&
      profile.subjects.Literacy.bookings.length > 0)
      ? 1
      : 0;

  const [tabIndex, setTabIndex] = useState<number>(defaultTabIndex);

  const handleTabsChange = (index: number): void => setTabIndex(index);

  // Selected booking to cancel
  const [selectedBooking, setSelectedBooking] = useState<DeleteBookingState>();
  const handleSelect = (booking: DeleteBookingState): void => {
    setSelectedBooking(booking);
    onOpen();
  };

  return (
    <>
      <BreadcrumbNav
        links={[
          {
            href: '/parent/subscriptions/manage',
            text: 'Subscription',
            isCurrentPage: false,
          },
          {
            href: '/parent/subscriptions/manage/bookings',
            text: 'Manage Bookings',
            isCurrentPage: true,
          },
        ]}
      />
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        variant="unstyled"
        size="md"
        margin="0 auto"
        w="100%"
        defaultIndex={defaultTabIndex}
      >
        <TabList
          borderLeft="1px solid"
          borderColor="gray.500"
          roundedTopLeft={8}
        >
          <Tab
            w="130px"
            color="teal.500"
            backgroundColor="gray.300"
            fontWeight="bold"
            border="1px solid"
            px={8}
            py={3}
            borderColor="gray.500"
            roundedTop={8}
            mb="-1px"
            _first={{
              marginRight: 1,
              borderLeft: 'none',
            }}
            _selected={{
              bg: 'white',
              borderBottom: '1px solid transparent',
            }}
          >
            Spanish
          </Tab>
          <Tab
            w="130px"
            color="teal.500"
            backgroundColor="gray.300"
            fontWeight="bold"
            border="1px solid"
            px={8}
            py={3}
            borderColor="gray.500"
            roundedTop={8}
            mb="-1px"
            _first={{
              marginRight: 1,
              borderLeft: 'none',
            }}
            _selected={{
              bg: 'white',
              borderBottom: '1px solid transparent',
            }}
          >
            Literacy
          </Tab>
        </TabList>

        <TabPanels
          border="1px solid"
          borderColor="gray.500"
          backgroundColor="white"
          rounded={8}
          color="teal.500"
          roundedTopLeft={0}
          boxShadow="lg"
        >
          <TabPanel>
            {!profile.subjects.Spanish.subscription ? (
              <NoSubscription subject="spanish" />
            ) : profile.subjects.Spanish.bookings.length === 0 ? (
              <NoBookings
                subject="spanish"
                classesPerWeek={
                  profile.subjects.Spanish.subscription.plan.classesPerWeek
                }
              />
            ) : (
              profile.subjects.Spanish.bookings.map((booking) => {
                const student = profile.students.all.find(
                  ({ id }) => id === booking.studentId
                )!;

                return (
                  <BookingManager
                    key={booking.id}
                    student={student}
                    handleSelect={() => handleSelect({ ...booking, student })}
                    {...booking}
                  />
                );
              })
            )}
          </TabPanel>
          <TabPanel>
            {!profile.subjects.Literacy.subscription ? (
              <NoSubscription subject="literacy" />
            ) : profile.subjects.Literacy.bookings.length === 0 ? (
              <NoBookings
                subject="literacy"
                classesPerWeek={
                  profile.subjects.Literacy.subscription.plan.classesPerWeek
                }
              />
            ) : (
              profile.subjects.Literacy.bookings.map((booking) => {
                const student = profile.students.all.find(
                  ({ id }) => id === booking.studentId
                )!;

                return (
                  <BookingManager
                    key={booking.id}
                    student={student}
                    handleSelect={() => handleSelect({ ...booking, student })}
                    {...booking}
                  />
                );
              })
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      {selectedBooking && (
        <DeleteBookingModal
          isOpen={isOpen}
          onClose={onClose}
          booking={selectedBooking}
        />
      )}
    </>
  );
};

export default BookingManagerTabs;
