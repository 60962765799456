import React from 'react';
import type { FC } from 'react';
import type { WindowLocation } from '@reach/router';
import { useFormik } from 'formik';
import {
  addMinutes,
  differenceInMinutes,
  endOfToday,
  isSameDay,
  isToday,
  startOfToday,
} from 'date-fns';

import {
  Box,
  FormControl,
  FormErrorMessage,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core';

import API from 'api';
import {
  EinsteinButton,
  EinsteinDatePicker,
  EinsteinTextarea,
} from 'components/_lib';
import { parseQueryString } from 'utils';
import type { Student } from 'types';

// UnavailabilityForm Formik form types
interface UnavailabilityFormValues {
  startDatetime: string;
  endDatetime: string;
  reason: string;
}

// UnavailabilityForm component prop types
interface UnavailabilityFormProps {
  student: Student;
  location: WindowLocation;
}

/**
 * Parent set unavailability form
 */
const UnavailabilityForm: FC<UnavailabilityFormProps> = ({
  student: { id: studentId, username },
  location: { search },
}: UnavailabilityFormProps) => {
  const now = new Date();

  // Submission success/failure toast
  const toast = useToast();

  // Form state and handlers
  const formik = useFormik<UnavailabilityFormValues>({
    initialValues: {
      startDatetime: parseQueryString('startDatetime', search) || '',
      endDatetime: parseQueryString('endDatetime', search) || '',
      reason: '',
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      if (!values.startDatetime) {
        errors.startDatetime = 'Required';
      }
      if (!values.endDatetime) {
        errors.endDatetime = 'Required';
      }

      const startDatetime =
        values.startDatetime && new Date(values.startDatetime);
      const endDatetime = values.endDatetime && new Date(values.endDatetime);

      if (startDatetime && startDatetime.getTime() < new Date().getTime()) {
        errors.startDatetime = 'Cannot be in past';
      }
      if (
        startDatetime &&
        endDatetime &&
        differenceInMinutes(endDatetime, startDatetime) < 25
      ) {
        errors.endDatetime = 'Must be at least 25 min after start';
      }

      return errors;
    },
    onSubmit: async ({ startDatetime, endDatetime, reason }, { resetForm }) => {
      try {
        await API.post('/unavailabilities/student', {
          studentId,
          startDatetime,
          endDatetime,
          reason: reason.length > 0 ? reason : undefined,
        });

        resetForm({
          values: { startDatetime: '', endDatetime: '', reason: '' },
        });

        toast({
          title: 'Success!',
          status: 'success',
          description: 'Unavailability logged!',
          duration: 4500,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Failure',
          status: 'error',
          description:
            error.response.status === 409
              ? 'Duplicate unavailability'
              : 'Could not log unavailability',
          duration: 4500,
          isClosable: true,
        });
      }
    },
  });

  const startTimeIsInvalid =
    formik.touched.startDatetime === true &&
    formik.errors.startDatetime !== undefined;

  const endTimeIsInvalid =
    formik.touched.endDatetime === true &&
    formik.errors.endDatetime !== undefined;

  return (
    <Stack
      color="teal.500"
      spacing={8}
      align="center"
      justify="center"
      w="100%"
    >
      <Text fontWeight="bold">
        {username} will be unable to attend class from:
      </Text>
      <Box w="375px" maxW="90%">
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={8} align="center" w="100%">
            <FormControl w="100%" isInvalid={startTimeIsInvalid}>
              <EinsteinDatePicker
                id="startDatetime"
                name="startDatetime"
                minDate={now}
                minTime={
                  formik.values.startDatetime !== '' &&
                  isToday(new Date(formik.values.startDatetime))
                    ? new Date()
                    : startOfToday()
                }
                maxTime={endOfToday()}
                placeholderText="Start Time (MM/DD/YYYY, HH:mm)"
                value={
                  formik.values.startDatetime !== ''
                    ? new Date(formik.values.startDatetime).toISOString()
                    : ''
                }
                setFieldValue={formik.setFieldValue}
                onBlur={formik.handleBlur}
                isInvalid={startTimeIsInvalid}
              />
              <FormErrorMessage>
                {formik.touched.startDatetime && formik.errors.startDatetime}
              </FormErrorMessage>
            </FormControl>
            <Text mt={-4}>to</Text>
            <FormControl w="100%" mt={-4} isInvalid={endTimeIsInvalid}>
              <EinsteinDatePicker
                id="endDatetime"
                name="endDatetime"
                minDate={new Date(formik.values.startDatetime || now)}
                minTime={
                  formik.values.startDatetime !== '' &&
                  formik.values.endDatetime !== '' &&
                  isSameDay(
                    new Date(formik.values.startDatetime),
                    new Date(formik.values.endDatetime)
                  )
                    ? addMinutes(new Date(formik.values.startDatetime), 30)
                    : startOfToday()
                }
                maxTime={endOfToday()}
                placeholderText="End Time (MM/DD/YYYY, HH:mm)"
                setFieldValue={formik.setFieldValue}
                onBlur={formik.handleBlur}
                value={
                  formik.values.endDatetime && formik.values.endDatetime !== ''
                    ? new Date(formik.values.endDatetime).toISOString()
                    : ''
                }
                isInvalid={endTimeIsInvalid}
              />
              <FormErrorMessage>
                {formik.touched.endDatetime && formik.errors.endDatetime}
              </FormErrorMessage>
            </FormControl>
            <EinsteinTextarea
              id="reason"
              name="reason"
              placeholder="Reason for unavailability (optional)..."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reason}
            />
            <EinsteinButton
              size="lg"
              type="submit"
              isLoading={formik.isSubmitting}
              loadingText="Submitting..."
              w="100%"
            >
              Submit
            </EinsteinButton>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default UnavailabilityForm;
