import React from 'react';
import type { FC } from 'react';

import { Stack } from '@chakra-ui/core';

import { withLayout, withSEO, withSpacing } from 'components/_hoc';
import { useActiveStudent } from 'hooks';
import HistoryTable from './HistoryTable';
import UpcomingTable from './UpcomingTable';

/**
 * Wraps parent root home page
 */
const RootPage: FC<{}> = () => {
  const student = useActiveStudent()!;

  return (
    <Stack spacing={16} shouldWrapChildren>
      <UpcomingTable student={student} />
      <HistoryTable student={student} />
    </Stack>
  );
};

export default withSEO(withLayout(withSpacing(RootPage)), {
  title: 'Home • Parent',
  description:
    "Parent home dashboard for Einstein Studios. Manage your student's upcoming schedule and view their class history.",
  pathname: '/parent/home',
});
