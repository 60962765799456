import React, { memo } from 'react';
import type { FC } from 'react';

import { Flex, Stack, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';

import ValidationCircleSvg from 'assets/svgs/validation-circle.svg';
import { RouterLink } from 'components/_lib';
import colors from 'theme/colors';
import { ActionTypes, SubscriptionActionsModalContainerProps } from './types';

// Orange color span
const StyledSpan = styled.span`
  color: ${colors.orange['500']};
`;

// ThankYouBody component prop types
interface ThankYouBodyProps
  extends Pick<SubscriptionActionsModalContainerProps, 'type'> {
  isUpgrade?: boolean;
}

/**
 * Modal body for package purchase/update thank you
 */
const ThankYouBody: FC<ThankYouBodyProps> = ({
  type,
  isUpgrade,
}: ThankYouBodyProps) => (
  <Stack spacing={8} w="100%" align="center" justify="center" mb={4}>
    <Flex w="5rem" h="5rem">
      <ValidationCircleSvg />
    </Flex>
    <Stack spacing={4} textAlign="center" w="90%">
      <Text fontWeight="bold">Thank you for your trust!</Text>
      {type === ActionTypes.purchase ? (
        <Text>
          Your subscription has been <StyledSpan>activated</StyledSpan>. You can
          now go to the{' '}
          <RouterLink to="/parent/classes" color="orange.500">
            Classes
          </RouterLink>{' '}
          page to book recurring classes!
        </Text>
      ) : type === ActionTypes.changeSubscription && isUpgrade ? (
        <Text>
          Your subscription has been <StyledSpan>upgraded</StyledSpan>, and your
          billing cycle has been reset to today. You can begin booking more
          recurring classes now!
        </Text>
      ) : type === ActionTypes.changeSubscription ? (
        <Text>
          Your subscription <StyledSpan>downgrade</StyledSpan> request has been
          received. The request will be processed at your next billing date.
        </Text>
      ) : type === ActionTypes.changePaymentMethod ? (
        <Text>
          Your payment method has been <StyledSpan>updated</StyledSpan>. Future
          payments will now be charged to your new card.
        </Text>
      ) : type === ActionTypes.cancelSubscription ? (
        <Text>
          Your subscription <StyledSpan>cancellation</StyledSpan> request has
          been received. Your subscription will be cancelled at your next
          billing date.
        </Text>
      ) : type === ActionTypes.cancelCancellation ? (
        <Text>Your pending subscription cancellation has been terminated.</Text>
      ) : (
        <Text>Your pending subscripion update has been terminated.</Text>
      )}
    </Stack>
  </Stack>
);

export default memo(ThankYouBody);
