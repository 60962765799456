import React from 'react';
import type { FC } from 'react';
import { useMatch } from '@reach/router';
import { useQuery } from 'react-query';
import type { QueryFunction } from 'react-query';
import { format, parseISO } from 'date-fns';

import {
  Avatar,
  Box,
  Icon,
  Link,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import type { StackProps } from '@chakra-ui/core';
import { FaQuoteRight } from 'react-icons/fa';

import API from 'api';
import {
  ButtonGatsbyLink,
  BreadcrumbNav,
  MobileSection,
  MobileSectionTitle,
  MobileSectionGroup,
  MobileSectionItem,
  Section,
  SectionBody,
  SectionTitle,
} from 'components/_lib';
import { withLayout, withSEO } from 'components/_hoc';
import { useActiveStudent } from 'hooks';
import type { StudentClassFeedback } from 'types';

/**
 * React Query function to retrieve class feedback.
 */
const fetchFeedback: QueryFunction<
  StudentClassFeedback | null,
  [string, { studentId: string; classId: string }]
> = async (_, { studentId, classId }) => {
  try {
    const response = await API.get<StudentClassFeedback>(
      `/classes/${classId}/feedback`,
      { params: { studentId } }
    );
    return response.data;
  } catch {
    return null;
  }
};

/**
 * Component rendered when on feedback is found
 */
const NoFeedback: FC<StackProps> = ({ ...rest }: StackProps) => (
  <Stack align="center" justify="center" spacing={4} {...rest}>
    <Text fontWeight="bold">No feedback.</Text>
    <ButtonGatsbyLink
      to="/parent/home"
      variantColor="orange"
      rounded={8}
      fontFamily="heading"
      textTransform="uppercase"
    >
      Return Home
    </ButtonGatsbyLink>
  </Stack>
);

/**
 * Teacher-to-student feedback
 */
const FeedbackPage: FC<{}> = () => {
  const { id: studentId } = useActiveStudent()!;
  const { classId } = useMatch('/parent/home/history/:classId') as any;

  const { data: feedback, status } = useQuery(
    ['feedback', { studentId, classId }],
    fetchFeedback
  );

  const title = `${feedback?.subject.name} ${
    feedback?.isTrial ? ' Assessment' : 'Class'
  }`;
  const datetime = feedback && format(parseISO(feedback.datetime), 'LLL d, p');

  return (
    <>
      {/* Mobile version */}
      <MobileSection>
        <Skeleton
          w="100%"
          rounded={8}
          h={status === 'loading' ? '200px' : 'auto'}
          isLoaded={status !== 'loading'}
        >
          {!feedback ? (
            <MobileSectionGroup
              d="flex"
              h="150px"
              alignItems="center"
              justifyContent="center"
            >
              <NoFeedback />
            </MobileSectionGroup>
          ) : (
            <>
              <MobileSectionTitle
                backTo="/parent/home/history"
                textAlign="center"
              >
                {title}
                <br />
                {datetime}
              </MobileSectionTitle>
              <MobileSectionItem flexDirection="column">
                <Box
                  w="100px"
                  h="100px"
                  position="absolute"
                  right="10%"
                  top="24rem"
                  opacity={0.22}
                  color="orange.500"
                  as={FaQuoteRight}
                />
                <Stack spacing={4} alignItems="center">
                  <Avatar
                    size="xl"
                    src={feedback.teacher.picture || undefined}
                    name={feedback.teacher.fullName}
                  />
                  <Text fontWeight="bold" fontSize="lg">
                    {feedback.teacher.fullName}
                  </Text>
                  <Link
                    isExternal
                    href={`mailto:${feedback.teacher.primaryEmail}`}
                  >
                    <Icon name="email" size="1.5rem" />
                  </Link>
                </Stack>
                <Text mt={8} textAlign="center">
                  {feedback.text}
                </Text>
              </MobileSectionItem>
            </>
          )}
        </Skeleton>
      </MobileSection>

      {/* Desktop version */}
      <>
        <BreadcrumbNav
          links={[
            { href: '/parent/home', text: 'Home', isCurrentPage: false },
            {
              href: '/parent/home/history',
              text: 'History',
              isCurrentPage: false,
            },
            {
              href: `/parent/home/history/${classId}`,
              text: 'Feedback',
              isCurrentPage: true,
            },
          ]}
        />
        <Section>
          <Skeleton
            h={status === 'loading' ? '200px' : 'auto'}
            w="100%"
            rounded={8}
            isLoaded={status !== 'loading'}
          >
            {!feedback ? (
              <NoFeedback
                minH={[
                  'calc(100vh - 164px - 4rem)',
                  'calc(100vh - 168px - 4rem)',
                  'calc(100vh - 172px - 12rem)',
                  'calc(100vh - 180px - 12rem)',
                ]}
              />
            ) : (
              <>
                <SectionTitle borderBottom="none">
                  {title} - {datetime}
                </SectionTitle>
                <SectionBody
                  w="100%"
                  border="1px solid"
                  borderColor="gray.500"
                  rounded={8}
                  p={8}
                  color="teal.500"
                >
                  <Box
                    w="100px"
                    h="100px"
                    mt="15px"
                    float="right"
                    opacity={0.22}
                    color="orange.500"
                    as={FaQuoteRight}
                  />
                  <Stack spacing={4} isInline alignItems="center">
                    <Avatar
                      size="xl"
                      name={feedback.teacher.fullName}
                      src={feedback.teacher.picture || undefined}
                    />
                    <Text fontWeight="bold" fontSize="lg">
                      {feedback.teacher.fullName}
                    </Text>
                    <Tooltip
                      aria-label="contact"
                      label="Contact"
                      placement="bottom"
                    >
                      <Link
                        isExternal
                        href={`mailto:${feedback.teacher.primaryEmail}`}
                      >
                        <Icon name="email" size="1.5rem" />
                      </Link>
                    </Tooltip>
                  </Stack>
                  <Text mt={4} ml="calc(84px + 1rem)" w="70%">
                    {feedback.text}
                  </Text>
                </SectionBody>
              </>
            )}
          </Skeleton>
        </Section>
      </>
    </>
  );
};

export default withSEO(withLayout(FeedbackPage), {
  title: 'Feedback • Parent',
  description: 'Student class feedback.',
  pathname: '/parent/home/history',
});
