import React from 'react';
import type { FC } from 'react';
import { useFormik } from 'formik';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/core';

import API from 'api';
import { withSEO } from 'components/_hoc';
import {
  ButtonGatsbyLink,
  EinsteinButton,
  EinsteinInput,
} from 'components/_lib';
import { useParent } from 'hooks';

// Formik form value types
interface EditScreenFormValues {
  fullName: string;
  preferredName: string;
  primaryEmail: string;
  alternateEmail: string;
}

/**
 * Form to edit parent profile details.
 */
const EditScreen: FC<{}> = () => {
  const { profile } = useParent()!;

  // Request status toast
  const toast = useToast();

  // Form state and handlers
  const formik = useFormik<EditScreenFormValues>({
    initialValues: {
      fullName: profile.fullName,
      preferredName: profile.preferredName,
      primaryEmail: profile.primaryEmail,
      alternateEmail: profile.alternateEmail || '',
    },
    validate: ({ fullName, preferredName, primaryEmail }) => {
      const errors: { [key: string]: string } = {};

      if (!fullName) {
        errors.fullName = 'Cannot be empty.';
      }
      if (!preferredName) {
        errors.preferredName = 'Cannot be empty.';
      }
      if (!primaryEmail) {
        errors.primaryEmail = 'Cannot be empty.';
      }

      return errors;
    },
    onSubmit: async ({
      fullName,
      preferredName,
      primaryEmail,
      alternateEmail,
    }) => {
      try {
        await API.patch('/parents/user', {
          fullName,
          preferredName,
          primaryEmail,
          alternateEmail: alternateEmail === '' ? null : alternateEmail,
        });
        window.location.reload();
      } catch {
        toast({
          title: 'Error',
          description: 'Could not update account.',
          status: 'error',
          duration: 4500,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Stack
      minH="100vh"
      color="teal.500"
      spacing={8}
      align="center"
      justify="center"
      p={[4, 8, 12, 16]}
    >
      <Heading fontFamily="heading" size="lg" textTransform="uppercase">
        Profile Modification
      </Heading>
      <Box w="375px" maxW="90%">
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={8} align="center">
            <FormControl
              isInvalid={
                formik.touched.fullName === true &&
                formik.errors.fullName !== undefined
              }
              w="100%"
            >
              <FormLabel htmlFor="fullName">Full Name</FormLabel>
              <EinsteinInput
                id="fullName"
                name="fullName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fullName}
              />
              <FormErrorMessage>
                {formik.touched.fullName && formik.errors.fullName}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.preferredName === true &&
                formik.errors.preferredName !== undefined
              }
              w="100%"
            >
              <FormLabel htmlFor="preferredName">Preferred Name</FormLabel>
              <EinsteinInput
                id="preferredName"
                name="preferredName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.preferredName}
              />
              <FormHelperText
                id="preferredName-helper-text"
                color="teal.500"
                opacity={0.36}
              >
                How we&apos;ll address you in emails.
              </FormHelperText>
              <FormErrorMessage>
                {formik.touched.preferredName && formik.errors.preferredName}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.primaryEmail === true &&
                formik.errors.primaryEmail !== undefined
              }
              w="100%"
            >
              <FormLabel htmlFor="primaryEmail">Primary Email</FormLabel>
              <EinsteinInput
                id="primaryEmail"
                name="primaryEmail"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.primaryEmail}
              />
              <FormErrorMessage>
                {formik.touched.primaryEmail && formik.errors.primaryEmail}
              </FormErrorMessage>
            </FormControl>
            <FormControl w="100%">
              <FormLabel htmlFor="alternateEmail">
                Alternate Email (optional)
              </FormLabel>
              <EinsteinInput
                id="alternateEmail"
                name="alternateEmail"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.alternateEmail}
              />
              <FormHelperText
                id="alternateEmail-helper-text"
                color="teal.500"
                opacity={0.36}
              >
                A second email that you can log in with.
              </FormHelperText>
            </FormControl>
            <Stack isInline spacing={4}>
              <ButtonGatsbyLink
                to="/parent/profile/manage"
                size="lg"
                variant="outline"
                w="150px"
              >
                Back
              </ButtonGatsbyLink>
              <EinsteinButton
                size="lg"
                w="150px"
                type="submit"
                onClick={formik.handleSubmit}
                isLoading={formik.isSubmitting}
                loadingText="Saving..."
              >
                Save
              </EinsteinButton>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default withSEO(EditScreen, {
  title: 'Edit Profile • Parent',
  description: 'Edit parent profile details on Einstein Studios.',
  pathname: '/parent/profile/edit',
});
