import React, { useState } from 'react';
import type { FC } from 'react';

import {
  Box,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
} from '@chakra-ui/core';

import EinsteinStudiosLogoUpright from 'assets/svgs/einstein-studios-logo-upright.svg';
import { useParent } from 'hooks';
import type { Coupon } from 'types';

import CancelBody from './CancelBody';
import ChangeBody from './ChangeBody';
import PurchaseBody from './PurchaseBody';
import CouponBody from './CouponBody';
import PaymentMethodBody from './PaymentMethodBody';
import ThankYouBody from './ThankYouBody';
import {
  ActionTypes,
  ModalScreens,
  SubscriptionActionsModalContainerProps,
} from './types';

/**
 * Wraps modal to purchase, upgrade or update subscription on Stripe
 */
const SubscriptionActionsModalContainer: FC<SubscriptionActionsModalContainerProps> = ({
  plan,
  subscription,
  type,
  isOpen,
  onClose,
}: SubscriptionActionsModalContainerProps) => {
  // Select authenticated parent
  const { profile } = useParent()!;

  let initialScreen: ModalScreens;
  if (
    type === ActionTypes.purchase ||
    type === ActionTypes.changeSubscription
  ) {
    initialScreen = ModalScreens.purchase;
  } else if (
    type === ActionTypes.cancelCancellation ||
    type === ActionTypes.cancelSubscription ||
    type === ActionTypes.cancelUpdate
  ) {
    initialScreen = ModalScreens.cancel;
  } else {
    initialScreen = ModalScreens.paymentMethod;
  }

  // Controls rendered modal body
  const [screen, setScreen] = useState<ModalScreens>(initialScreen);
  const navigateToPurchase: VoidFunction = () => {
    setScreen(ModalScreens.purchase);
  };
  const navigateToDiscount: VoidFunction = () => {
    setScreen(ModalScreens.coupon);
  };
  const navigateToChange: VoidFunction = () => {
    setScreen(ModalScreens.change);
  };
  const navigateToPaymentMethod: VoidFunction = () => {
    setScreen(ModalScreens.paymentMethod);
  };
  const navigateToThankYou: VoidFunction = () => {
    setScreen(ModalScreens.thankYou);
  };

  // Verified Stripe subscription coupon
  const [coupon, setCoupon] = useState<Coupon>();
  const selectCoupon = (c: Coupon): void => setCoupon(c);

  /**
   * Close modal and reset state
   */
  const handleClose: VoidFunction = () => {
    onClose();
    setScreen(initialScreen);
    setCoupon(undefined);
  };

  const isOnSecondaryScreen =
    screen === ModalScreens.coupon ||
    screen === ModalScreens.change ||
    (screen === ModalScreens.paymentMethod && type === ActionTypes.purchase);

  /**
   * Corner icon button click handler
   */
  const handleCornerButtonClick: VoidFunction = () => {
    if (screen === ModalScreens.thankYou) {
      handleClose();
      if (window.location.pathname.includes('/subscriptions/purchase')) {
        window.location.href = `${process.env.WEB_PLATFORM_URL}/parent/subscriptions/manage`;
      } else {
        window.location.reload();
      }
    } else if (isOnSecondaryScreen) {
      navigateToPurchase();
    } else {
      handleClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size={['full', 'lg']}
      preserveScrollBarGap
      closeOnOverlayClick={screen !== ModalScreens.thankYou}
      closeOnEsc={screen !== ModalScreens.thankYou}
    >
      <ModalOverlay />
      <ModalContent borderRadius={[0, 8]} color="teal.500">
        <IconButton
          variant="ghost"
          w="min-content"
          alignSelf="flex-start"
          rounded={8}
          mt={2}
          ml={2}
          aria-label={isOnSecondaryScreen ? 'back' : 'close'}
          icon={
            isOnSecondaryScreen
              ? () => <Icon name="chevron-left" fontSize="24px" />
              : 'close'
          }
          onClick={handleCornerButtonClick}
        />
        <ModalBody>
          <Stack
            spacing={8}
            color="teal.500"
            align="center"
            justify="center"
            w="100%"
            mb={4}
          >
            <Box w="100%">
              <Box w="100px" m="0 auto">
                <EinsteinStudiosLogoUpright />
              </Box>
            </Box>
            <Box w="100%">
              {screen === ModalScreens.purchase ? (
                <PurchaseBody
                  plan={plan!}
                  type={type}
                  navigateToDiscount={navigateToDiscount}
                  handleClick={
                    type === ActionTypes.changeSubscription
                      ? navigateToChange
                      : navigateToPaymentMethod
                  }
                  coupon={coupon}
                />
              ) : screen === ModalScreens.coupon ? (
                <CouponBody selectCoupon={selectCoupon} coupon={coupon} />
              ) : screen === ModalScreens.cancel ? (
                <CancelBody
                  navigateToThankYou={navigateToThankYou}
                  subscription={subscription!}
                  type={type}
                />
              ) : screen === ModalScreens.change ? (
                <ChangeBody
                  plan={plan!}
                  subscription={subscription!}
                  coupon={coupon && coupon.id}
                  navigateToThankYou={navigateToThankYou}
                  isUpgrade={plan!.price > subscription!.plan.price}
                />
              ) : screen === ModalScreens.paymentMethod ? (
                <PaymentMethodBody
                  type={type}
                  plan={plan?.id}
                  customer={subscription?.customerId}
                  name={profile.fullName}
                  email={profile.primaryEmail}
                  coupon={coupon && coupon.id}
                  navigateToThankYou={navigateToThankYou}
                />
              ) : (
                <ThankYouBody
                  type={type}
                  isUpgrade={
                    plan !== undefined &&
                    subscription !== undefined &&
                    plan.price > subscription.plan.price
                  }
                />
              )}
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SubscriptionActionsModalContainer;
