import React, { useState } from 'react';
import type { FC } from 'react';
import { format, parseISO } from 'date-fns';

import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/core';
import type { ButtonProps } from '@chakra-ui/core';

import { MobileSectionGroup, MobileSectionItem } from 'components/_lib';
import { WEEKDAYS } from 'data';
import type { RecurringAvailability } from 'types';
import type { RecurringAvailabilityState } from '../types';

/**
 * Button for a specific availability time
 */
const AvailabilityButton: FC<ButtonProps> = ({
  children,
  ...rest
}: ButtonProps) => (
  <Button
    mx={[0, null, 2]}
    rounded={8}
    variantColor="turqouise"
    w={['80px', null, '65px']}
    fontSize={['.85rem', null, '.75rem']}
    {...rest}
  >
    {children}
  </Button>
);

// CalendarWeek component prop types
interface CalendarWeekProps
  extends Pick<RecurringAvailabilityState, 'teacher'> {
  availabilities: RecurringAvailability[][];
  handleSelect: (availability: RecurringAvailabilityState) => void;
}

// Weekday component prop types
interface WeekdayProps extends Omit<CalendarWeekProps, 'availabilities'> {
  day: typeof WEEKDAYS[0];
  availabilities: RecurringAvailability[];
}

/**
 * Desktop component rendering all recurring availabilities for a weekday
 */
const Weekday: FC<WeekdayProps> = ({
  day,
  teacher,
  availabilities,
  handleSelect,
}: WeekdayProps) => (
  <Flex alignItems="center" flexDirection="column">
    <Text py={2} px={4} fontWeight="bold">
      {day.abbreviation}
    </Text>
    <Box borderBottomColor="teal.500" borderBottomWidth="1px" w="100%" />
    <Stack spacing={4} py={4} shouldWrapChildren>
      {availabilities.map((availability) => (
        <AvailabilityButton
          key={availability.id}
          onClick={() => handleSelect({ teacher, availability })}
        >
          {format(parseISO(availability.firstClass), 'p')}
        </AvailabilityButton>
      ))}
    </Stack>
  </Flex>
);

/**
 * Mobile component rendering all availabilities for a weekday
 */
const MobileWeekday: FC<WeekdayProps> = ({
  day,
  teacher,
  availabilities,
  handleSelect,
}: WeekdayProps) => {
  // Collapse open/closed boolean
  const [show, setShow] = useState(false);

  // Toggle collapse open/closed
  const handleToggle: VoidFunction = () => setShow(!show);

  return (
    <MobileSectionItem
      d={['block', null, 'none']}
      p={2}
      _last={{ roundedBottom: 8 }}
    >
      <Stack isInline justifyContent="space-between" alignItems="center" p={2}>
        <Heading size="sm" fontFamily="body">
          {day.long}
        </Heading>
        <IconButton
          aria-label="show availabilities"
          icon={show ? 'chevron-up' : 'chevron-down'}
          variant="ghost"
          fontSize="24px"
          onClick={handleToggle}
        />
      </Stack>
      <Collapse mt={4} isOpen={show}>
        <SimpleGrid columns={[3, 4]} spacing={[1, 2]}>
          {availabilities.map((availability) => (
            <Box m="0 auto">
              <AvailabilityButton
                key={availability.id}
                onClick={() => handleSelect({ teacher, availability })}
              >
                {format(parseISO(availability.firstClass), 'p')}
              </AvailabilityButton>
            </Box>
          ))}
        </SimpleGrid>
      </Collapse>
    </MobileSectionItem>
  );
};

/**
 * Renders a full week of recurring availabilities for a given teacher
 */
export const CalendarWeek: FC<CalendarWeekProps> = ({
  teacher,
  availabilities,
  handleSelect,
}: CalendarWeekProps) => (
  <Stack isInline justifyContent={{ md: 'center' }}>
    {WEEKDAYS.map((day, index) => (
      <Weekday
        key={day.code}
        day={day}
        teacher={teacher}
        availabilities={availabilities[index]}
        handleSelect={handleSelect}
      />
    ))}
  </Stack>
);

/**
 * Renders a full week of availabilities for a given teacher on mobile
 */
export const MobileCalendarWeek: FC<CalendarWeekProps> = ({
  teacher,
  availabilities,
  handleSelect,
}: CalendarWeekProps) => (
  <MobileSectionGroup>
    {WEEKDAYS.map((day, index) =>
      availabilities[index].length > 0 ? (
        <MobileWeekday
          key={day.code}
          day={day}
          teacher={teacher}
          availabilities={availabilities[index]}
          handleSelect={handleSelect}
        />
      ) : null
    )}
  </MobileSectionGroup>
);
