import { Subscription } from 'types';
import { PlanElement } from '../plans';

// Enumeration of modal bodies
export enum ModalScreens {
  purchase,
  coupon,
  paymentMethod,
  change,
  cancel,
  thankYou,
}

// Enumeration of modal body actions
export enum ActionTypes {
  purchase,
  changeSubscription,
  changePaymentMethod,
  cancelSubscription,
  cancelUpdate,
  cancelCancellation,
}

// SubscriptionActionsModalContainer component prop types
export interface SubscriptionActionsModalContainerProps {
  plan?: PlanElement;
  subscription?: Subscription;
  type: ActionTypes;
  isOpen: boolean;
  onClose: VoidFunction;
}
