import React, { useState } from 'react';
import type { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { useMutation } from 'react-query';

import { Avatar, Box, Icon, Stack, Text } from '@chakra-ui/core';

import API from 'api';
import { EinsteinButton, ErrorMessage } from 'components/_lib';
import { useParent } from 'hooks';
import type {
  ErrorState,
  OneTimeAvailability,
  RecurringAvailability,
  Student,
} from 'types';
import { capitalize } from 'utils';
import { WEEKDAYS, WeekdaysEnum } from 'data';
import type { ConfirmationModalContainerProps } from './types';

// NewClassBody component prop types
interface NewClassBodyProps
  extends Pick<
    ConfirmationModalContainerProps,
    'teacher' | 'availability' | 'isOneOnOne' | 'subject' | 'type'
  > {
  student: Student;
  transitionToThankYou: VoidFunction;
}

/**
 * Modal content for confirming new bookings or one-time classes
 */
const NewClassBody: FC<NewClassBodyProps> = ({
  type,
  teacher,
  availability,
  student,
  subject,
  isOneOnOne,
  transitionToThankYou,
}: NewClassBodyProps) => {
  const { profile } = useParent()!;

  const data =
    profile.subjects[
      subject.toLowerCase() === 'literacy' ? 'Literacy' : 'Spanish'
    ];

  const noCredits = type === 'one_time' && data.credits === 0;
  const noSubscription = type === 'recurring' && !data.subscription;
  const noMoreBookings =
    type === 'recurring' &&
    data.subscription !== undefined &&
    data.bookings.filter(
      (booking) => booking.subject.name.toLowerCase() === subject.toLowerCase()
    ).length >= data.subscription.plan.classesPerWeek;

  const [errorMessage, setErrorMessage] = useState<ErrorState>({
    isActive: noCredits || noSubscription || noMoreBookings,
    message: noCredits
      ? `You do not own any one-time ${subject} credits.`
      : noSubscription
      ? `You do not own an active ${subject} subscription.`
      : noMoreBookings
      ? `Your ${subject} subscription permits no more bookings.`
      : '',
  });

  const [mutate, { status }] = useMutation(
    async (): Promise<void> => {
      await API.post(type === 'recurring' ? '/bookings' : '/classes', {
        studentId: student.id,
        availabilityId: availability.id,
        isOneOnOne,
        subject,
        firstClass:
          type === 'recurring'
            ? (availability as RecurringAvailability).firstClass
            : undefined,
        datetime:
          type === 'one_time'
            ? (availability as OneTimeAvailability).datetime
            : undefined,
      });
    },
    {
      onSuccess: (): void => {
        if (errorMessage.isActive) {
          setErrorMessage({ isActive: false, message: '' });
        }
        transitionToThankYou();
      },
      onError: (): void => {
        setErrorMessage({
          isActive: true,
          message:
            type === 'recurring'
              ? 'Could not create booking at this time'
              : 'Could not book class at this time',
        });
      },
    }
  );

  const firstRecurringClass =
    type === 'recurring'
      ? parseISO((availability as RecurringAvailability).firstClass)
      : undefined;

  const oneTimeClass =
    type === 'one_time'
      ? parseISO((availability as OneTimeAvailability).datetime)
      : undefined;

  return (
    <Stack spacing={8} color="teal.500" align="center" p={8}>
      <Stack spacing={2} align="center">
        <Avatar size="xl" src={student.picture || undefined} />
        <Text fontWeight="bold">{student.username}</Text>
      </Stack>
      <Box w="85%" borderBottom="2px solid #f49d37" />
      <Stack isInline spacing={8} align="center">
        <Stack spacing={2} align="center" textAlign="center">
          <Avatar size="xl" src={teacher.picture || undefined} />
          <Text fontWeight="bold">{teacher.fullName}</Text>
        </Stack>
        <Stack spacing={2}>
          <Text>
            <strong>Subject:</strong>
            {` ${capitalize(subject)}`}
          </Text>
          {type === 'recurring' ? (
            <Stack spacing={2}>
              <Text>
                <strong>Day of week:</strong>{' '}
                {
                  WEEKDAYS[
                    WeekdaysEnum[
                      (availability as RecurringAvailability).dayOfWeek
                    ]
                  ].long
                }
                s
              </Text>
              <Text>
                <strong>Time:</strong> {format(firstRecurringClass!, 'p')}
              </Text>
              <Text>
                <strong>First class:</strong>{' '}
                {format(firstRecurringClass!, 'MMMM dd')}
              </Text>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Text>
                <strong>Date:</strong> {format(oneTimeClass!, 'PP')}
              </Text>
              <Text>
                <strong>Time:</strong> {format(oneTimeClass!, 'p')}
              </Text>
            </Stack>
          )}
          <Text d="flex" alignItems="center">
            <Icon name="time" mr={2} />
            {availability.durationInMinutes} min.{' '}
            {type === 'recurring' && 'per'} class
          </Text>
        </Stack>
      </Stack>
      <EinsteinButton
        variantColor="orange"
        size="lg"
        textTransform="uppercase"
        fontFamily="heading"
        onClick={() => mutate()}
        isLoading={status === 'loading'}
        loadingText="Booking..."
        isDisabled={noCredits || noSubscription || noMoreBookings}
      >
        {type === 'recurring' ? 'Create booking' : 'Book class'}
      </EinsteinButton>
      <ErrorMessage isActive={errorMessage.isActive}>
        {errorMessage.message}
      </ErrorMessage>
    </Stack>
  );
};

export default NewClassBody;
