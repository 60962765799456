import React, { useState } from 'react';
import type { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { useMutation } from 'react-query';

import { Avatar, Box, Stack, Text } from '@chakra-ui/core';

import API from 'api';
import { EinsteinButton, ErrorMessage } from 'components/_lib';
import type {
  Booking,
  ErrorState,
  RecurringAvailability,
  Student,
} from 'types';
import { getNextInstanceOfAvailability } from 'utils';
import { WEEKDAYS, WeekdaysEnum } from 'data';
import type { ConfirmationModalContainerProps } from './types';

// ChangeBookingBody component prop types
interface ChangeBookingBodyProps
  extends Pick<
    ConfirmationModalContainerProps,
    'teacher' | 'availability' | 'subject'
  > {
  bookingToChange: Booking;
  student: Student;
  transitionToThankYou: VoidFunction;
}

/**
 * Modal content for confirming new bookings or one-time classes
 */
const ChangeBookingBody: FC<ChangeBookingBodyProps> = ({
  teacher,
  availability,
  student,
  bookingToChange,
  subject,
  transitionToThankYou,
}: ChangeBookingBodyProps) => {
  const [errorMessage, setErrorMessage] = useState<ErrorState>({
    isActive: false,
    message: '',
  });

  const [mutate, { status }] = useMutation(
    async (): Promise<void> => {
      await API.put(`/bookings/${bookingToChange.id}`, {
        studentId: student.id,
        availabilityId: availability.id,
        firstClass: (availability as RecurringAvailability).firstClass,
        subject,
        isOneOnOne: bookingToChange.isOneOnOne,
      });
    },
    {
      onSuccess: (): void => {
        if (errorMessage.isActive) {
          setErrorMessage({ isActive: false, message: '' });
        }
        transitionToThankYou();
      },
      onError: (): void => {
        setErrorMessage({
          isActive: true,
          message: 'Could not change booking at this time.',
        });
      },
    }
  );

  const nextOldClass = getNextInstanceOfAvailability(
    bookingToChange.availability.dayOfWeek,
    bookingToChange.availability.startTime,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const firstNewClass = parseISO(
    (availability as RecurringAvailability).firstClass
  );

  return (
    <Stack spacing={8} color="teal.500" alignItems="center" p={8}>
      <Text fontWeight="bold">Confirming booking change:</Text>
      <Stack spacing={2} alignItems="center">
        <Avatar size="lg" src={student.picture || undefined} />
        <Text fontWeight="bold">{student.username}</Text>
      </Stack>

      <Box w="85%" borderBottom="2px solid #f49d37" />

      <Text>Old booking:</Text>
      <Stack isInline spacing={8} align="center">
        <Stack spacing={2} align="center">
          <Avatar
            size="lg"
            src={bookingToChange.teacher.picture || undefined}
          />
          <Text fontWeight="bold">{bookingToChange.teacher.fullName}</Text>
        </Stack>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Text>
              <strong>Day of week:</strong>{' '}
              {
                WEEKDAYS[WeekdaysEnum[bookingToChange.availability.dayOfWeek]]
                  .long
              }
              s
            </Text>
            <Text>
              <strong>Time:</strong> {format(nextOldClass, 'p')}
            </Text>
            <Text>
              <strong>Next class:</strong> {format(nextOldClass, 'MMMM dd')}
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <Text>New booking:</Text>
      <Stack isInline spacing={8} align="center">
        <Stack spacing={2} align="center">
          <Avatar size="lg" src={teacher.picture || undefined} />
          <Text fontWeight="bold">{teacher.fullName}</Text>
        </Stack>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Text>
              <strong>Day of week:</strong>{' '}
              {
                WEEKDAYS[
                  WeekdaysEnum[
                    (availability as RecurringAvailability).dayOfWeek
                  ]
                ].long
              }
              s
            </Text>
            <Text>
              <strong>Time:</strong> {format(firstNewClass, 'p')}
            </Text>
            <Text>
              <strong>First class:</strong> {format(firstNewClass, 'MMMM dd')}
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <EinsteinButton
        variantColor="orange"
        size="lg"
        textTransform="uppercase"
        fontFamily="heading"
        onClick={() => mutate()}
        isLoading={status === 'loading'}
        loadingText="Booking..."
      >
        Change Booking
      </EinsteinButton>
      <ErrorMessage isActive={errorMessage.isActive}>
        {errorMessage.message}
      </ErrorMessage>
    </Stack>
  );
};

export default ChangeBookingBody;
