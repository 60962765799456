import React from 'react';
import type { FC } from 'react';

import { EinsteinRouter, ParentRoute, Route } from 'components/_lib';
import { AuthProvider, ParentProvider } from 'components/_providers';
import {
  Classes,
  Home,
  Profile,
  Referral,
  Subscriptions,
  Unavailability,
} from 'components/ParentDashboard';

const DATA_URL = `${process.env.WEB_API_URL}/parents/user`;
const LOGOUT_URL = `${process.env.WEB_API_URL}/parents/user/logout`;

/**
 * Parent dashboard client-only router, routes to:
 *   /parent/home - Dashboard home
 *   /parent/classes - Book classes page
 *   /parent/subscriptions - Purchase/manage subscriptions page
 *   /parent/profile - Profile management page
 *   /parent/referral - Referral link page
 *   /parent/unavailability - Submit unavailabilities page
 */
const ParentPage: FC = () => (
  <AuthProvider scope="parent" dataUrl={DATA_URL} logoutUrl={LOGOUT_URL}>
    <ParentProvider>
      <EinsteinRouter basepath="/parent">
        <ParentRoute path="/home/*" component={Home} />
        <ParentRoute path="/classes/*" component={Classes} />
        <ParentRoute path="/subscriptions/*" component={Subscriptions} />
        <ParentRoute path="/referral" component={Referral} />
        <ParentRoute path="/unavailability" component={Unavailability} />
        <Route path="/profile/*" component={Profile} />
      </EinsteinRouter>
    </ParentProvider>
  </AuthProvider>
);

export default ParentPage;
