import React, { useState } from 'react';
import type { FC } from 'react';
import { useFormik } from 'formik';

import {
  Box,
  Heading,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from '@chakra-ui/core';

import API from 'api';
import { withSEO } from 'components/_hoc';
import {
  ButtonGatsbyLink,
  EinsteinButton,
  EinsteinInput,
} from 'components/_lib';
import EditableAvatar from './EditableAvatar';

// Formik form value types
interface CreateScreenFormValues {
  username: string;
}

/**
 * Form to create new student account.
 */
const CreateScreen: FC<{}> = () => {
  // New username picture
  const [picture, setPicture] = useState<string | undefined>();

  /**
   * Set the new student avatar.
   *
   * @param newPicture - New student avatar URL.
   */
  const handleEditPicture = (newPicture: string): void => {
    setPicture(newPicture);
  };

  // Form state and handlers
  const formik = useFormik<CreateScreenFormValues>({
    initialValues: {
      username: '',
    },
    validate: ({ username }) => (!username ? { username: 'Required' } : {}),
    onSubmit: async ({ username }, { setErrors }) => {
      try {
        await API.post(`/students`, { username, picture });
        window.location.href = `${process.env.WEB_PLATFORM_URL}/parent/profile/manage`;
      } catch (error) {
        setErrors({ username: error.message });
      }
    },
  });

  return (
    <Stack
      h="100vh"
      color="teal.500"
      spacing={8}
      align="center"
      justify="center"
    >
      <Heading size="lg" textTransform="uppercase">
        Create Student
      </Heading>
      <Box w="375px" maxW="90%">
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={8} align="center">
            <EditableAvatar
              src={picture}
              editable
              handleEdit={handleEditPicture}
            />
            <FormControl isInvalid={!formik.isValid} w="100%">
              <FormLabel htmlFor="username">Student Username</FormLabel>
              <EinsteinInput
                isRequired
                id="username"
                name="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
              />
              <FormErrorMessage>
                {formik.touched.username && formik.errors.username}
              </FormErrorMessage>
            </FormControl>
            <Stack isInline spacing={4}>
              <ButtonGatsbyLink
                to="/parent/profile/manage"
                size="lg"
                variant="outline"
                w="150px"
              >
                Cancel
              </ButtonGatsbyLink>
              <EinsteinButton
                size="lg"
                w="150px"
                type="submit"
                isLoading={formik.isSubmitting}
              >
                Create
              </EinsteinButton>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default withSEO(CreateScreen, {
  title: 'Create Student • Parent',
  description: 'Create a new student account on Einstein Studios.',
  pathname: '/parent/profile/create',
});
