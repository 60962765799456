import React, { useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useLocation } from '@reach/router';
import { useQuery } from 'react-query';
import type { QueryFunction } from 'react-query';
import { format, parseISO } from 'date-fns';

import Img from 'gatsby-image';
import {
  Avatar,
  Box,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';

import API from 'api';
import MoonSmoothSvg from 'assets/svgs/moon-smooth.svg';
import {
  BreadcrumbNav,
  EinsteinRadioButton,
  MobileSection,
  MobileSectionFooter,
  MobileSectionGroup,
  MobileSectionTitle,
  Section,
  SectionBody,
  SectionTitle,
} from 'components/_lib';
import { withLayout } from 'components/_hoc';
import { useActiveStudent, useParent, useSubjectImages } from 'hooks';

import ConfirmationModal from './ConfirmationModal';
import type {
  OneTimeAvailabilityState,
  OneTimeTeacherAvailabilities,
} from './types';

/*
 * === CONSTANTS ===
 */

// Number of availabilities to show per 'page'
const SLICE_SIZE = 6;

// Component rendered when no open availabilities are fetched
const NO_AVAILABILITIES = (
  <Stack
    spacing={4}
    p={8}
    align="center"
    bg="white"
    rounded={8}
    color="teal.500"
  >
    <Box w="100px">
      <MoonSmoothSvg />
    </Box>
    <Text fontWeight="bold">No open availabilities.</Text>
  </Stack>
);

// Loading skeletons
const SKELETONS = ['skeleton1', 'skeleton2'].map((key) => (
  <Skeleton key={key} h="200px" w="100%" rounded={8} />
));

/*
 * === HELPERS ===
 */

/**
 * React Query function to fetch open one-time availabilities
 */
const fetchOneTimeAvailabilities: QueryFunction<
  OneTimeTeacherAvailabilities[],
  [string, { studentId: string; subject: string; isOneOnOne: string }]
> = async (_, params): Promise<OneTimeTeacherAvailabilities[]> => {
  try {
    const response = await API.get('/availabilities/one_time', { params });
    return response.data;
  } catch {
    return [];
  }
};

/*
 * === COMPONENTS ===
 */

// PaginationButton component prop types
interface PaginationButtonsProps
  extends Pick<OneTimeAvailabilitiesMenuProps, 'availabilities'> {
  slice: number;
  setSlice: Dispatch<SetStateAction<number>>;
}

/**
 * Left and right buttons to paginate displayed available classes
 */
const PaginationButtons: FC<PaginationButtonsProps> = ({
  slice,
  setSlice,
  availabilities,
}: PaginationButtonsProps) => {
  // Up slice of classes
  const incrementSlice: VoidFunction = () => {
    setSlice(Math.min(availabilities.length, slice + SLICE_SIZE));
  };

  // Down slice of classes
  const decrementSlice: VoidFunction = () => {
    setSlice(Math.max(0, slice - SLICE_SIZE));
  };

  return (
    <Stack isInline alignItems="center" justifyContent="center">
      <IconButton
        variantColor="teal"
        variant="ghost"
        aria-label="Show further classes"
        icon={() => <Icon name="chevron-left" fontSize="2.5rem" />}
        onClick={decrementSlice}
        isDisabled={slice === 0}
      />
      <IconButton
        variantColor="teal"
        variant="ghost"
        aria-label="Show previous classes"
        icon={() => <Icon name="chevron-right" fontSize="2.5rem" />}
        onClick={incrementSlice}
        isDisabled={slice + SLICE_SIZE >= availabilities.length}
      />
    </Stack>
  );
};

// OneTimeAvailabilitiesMenu component prop types
interface OneTimeAvailabilitiesMenuProps extends OneTimeTeacherAvailabilities {
  handleSelect: (
    availability: OneTimeAvailabilityState['availability']
  ) => void;
}

/**
 * Renders radio buttons to select one-time availability to book for a given teacher
 */
const OneTimeAvailabilitiesMenu: FC<OneTimeAvailabilitiesMenuProps> = ({
  teacher,
  availabilities,
  handleSelect,
}: OneTimeAvailabilitiesMenuProps) => {
  const [slice, setSlice] = useState<number>(0);

  const grid = (
    <SimpleGrid
      columns={[2, 3]}
      w={availabilities.length === 0 ? '615px' : 'auto'}
      maxW="100%"
      minH={['none', null, '156px']}
    >
      {availabilities
        .slice(slice, Math.min(availabilities.length, slice + SLICE_SIZE))
        .map((availability) => {
          const key = `${teacher.id}-${availability.datetime}`;
          const datetime = parseISO(availability.datetime);

          return (
            <EinsteinRadioButton
              key={key}
              onClick={() => handleSelect(availability)}
              m={[2, null, 4]}
              d="block"
            >
              <Text fontWeight="bold">{format(datetime, 'eee, LLL dd')}</Text>
              <Text fontSize="sm" fontWeight="normal">
                {format(datetime, 'p')}
              </Text>
            </EinsteinRadioButton>
          );
        })}
    </SimpleGrid>
  );

  const buttons = (
    <PaginationButtons
      setSlice={setSlice}
      slice={slice}
      availabilities={availabilities}
    />
  );

  return (
    <>
      <MobileSection>
        <MobileSectionTitle
          avatar={<Avatar src={teacher.picture || undefined} />}
        >
          <Stack isInline align="center">
            <Heading size="md">{teacher.fullName}</Heading>
            {teacher.bio && (
              <Popover placement="bottom-end">
                <PopoverTrigger>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    aria-label={`${teacher.fullName} bio`}
                    icon="info-outline"
                  />
                </PopoverTrigger>
                <PopoverContent
                  zIndex={4}
                  w="400px"
                  textTransform="none"
                  fontWeight="normal"
                >
                  <PopoverArrow />
                  <PopoverBody>{teacher.bio}</PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Stack>
        </MobileSectionTitle>
        <MobileSectionGroup
          p={4}
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          {grid}
        </MobileSectionGroup>
        <MobileSectionFooter borderTop="1px solid" borderColor="#f5f5f5">
          {buttons}
        </MobileSectionFooter>
      </MobileSection>

      <Stack
        d={['none', null, 'flex']}
        key={teacher.id}
        spacing={{ md: 8, lg: 0 }}
        w="100%"
        border="1px solid #979797"
        borderRadius="8px"
        p={8}
        color="teal.500"
        justifyContent="space-around"
        flexDirection={{ md: 'column', lg: 'row' }}
      >
        <Stack
          w={{ md: '100%', lg: '35%' }}
          align="center"
          justify="center"
          isInline
          spacing={4}
        >
          <Avatar size="xl" src={teacher.picture || undefined} />
          <Stack>
            <Heading size="md">{teacher.fullName}</Heading>
            {teacher.bio && (
              <Stack isInline align="center" spacing={1}>
                <Text>Bio</Text>
                <Popover placement="bottom-start">
                  <PopoverTrigger>
                    <IconButton
                      size="sm"
                      variant="ghost"
                      aria-label={`${teacher.fullName} bio`}
                      icon="info-outline"
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    zIndex={4}
                    maxW={{ md: '375px', lg: '500px' }}
                  >
                    <PopoverArrow />
                    <PopoverBody>{teacher.bio}</PopoverBody>
                  </PopoverContent>
                </Popover>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack w={{ md: '100%', lg: '65%' }} justify="center" align="center">
          {grid}
          {buttons}
        </Stack>
      </Stack>
    </>
  );
};

// OneTimeClassMenu component prop types
interface OneTimeClassMenuProps {
  subject: string;
}

/**
 * Menu of one-time class availabilities
 */
const OneTimeClassMenu: FC<OneTimeClassMenuProps> = ({
  subject,
}: OneTimeClassMenuProps) => {
  const location = useLocation();
  const data = useSubjectImages();

  const { profile } = useParent()!;
  const { id: studentId } = useActiveStudent();

  const isOneOnOne =
    subject === 'literacy' ||
    profile.subjects.Spanish.subscription?.plan.isOneOnOne === true;

  // ConfirmationModal state
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Fetch all one-time availabilites from API
  const { data: oneTimeAvailabilities, status } = useQuery(
    [
      'oneTimeAvailabilities',
      { studentId, subject, isOneOnOne: isOneOnOne ? 'true' : 'false' },
    ],
    fetchOneTimeAvailabilities
  );

  // Chosen availability to book
  const [selected, setSelected] = useState<OneTimeAvailabilityState>();

  /**
   * Select an availability as active and open the confirmation modal.
   *
   * @param availability - Availability to set active.
   */
  const handleSelect = (availability: OneTimeAvailabilityState): void => {
    setSelected(availability);
    onOpen();
  };

  const subjectImage = (
    <Img
      style={{
        borderRadius: '100%',
        width: '2rem',
        height: '2rem',
      }}
      fluid={
        subject === 'spanish'
          ? data.spanishImage.childImageSharp.fluid
          : data.literacyImage.childImageSharp.fluid
      }
      alt={subject === 'spanish' ? 'Spanish flag' : 'stack of books'}
    />
  );

  return (
    <>
      {/* Section rendered on small screens */}
      <Stack d={['flex', null, 'none']} spacing={8} shouldWrapChildren>
        <MobileSection>
          <MobileSectionTitle
            backTo={`/parent/classes/${subject}`}
            avatar={subjectImage}
          >
            {subject}
          </MobileSectionTitle>
        </MobileSection>
        {status === 'loading' || !oneTimeAvailabilities
          ? SKELETONS
          : oneTimeAvailabilities.length === 0
          ? NO_AVAILABILITIES
          : oneTimeAvailabilities.map(({ teacher, availabilities }) =>
              availabilities.length > 0 ? (
                <OneTimeAvailabilitiesMenu
                  key={teacher.id}
                  teacher={teacher}
                  availabilities={availabilities}
                  handleSelect={(
                    availability: OneTimeAvailabilityState['availability']
                  ) => handleSelect({ teacher, availability })}
                />
              ) : null
            )}
      </Stack>

      {/* Section rendered on large screens */}
      <>
        <BreadcrumbNav
          links={[
            {
              href: '/parent/classes',
              text: subject === 'spanish' ? 'Spanish' : 'Literacy',
              isCurrentPage: false,
            },
            {
              href: `/parent/classes/${subject}`,
              text: 'One-Time',
              isCurrentPage: false,
            },
            {
              href: location.pathname,
              text: 'Availabilities',
              isCurrentPage: true,
            },
          ]}
        />
        <Section>
          <SectionTitle avatar={subjectImage}>{subject}</SectionTitle>
          <SectionBody w="100%">
            {status === 'loading' || !oneTimeAvailabilities ? (
              <Stack spacing={8}>{SKELETONS}</Stack>
            ) : oneTimeAvailabilities.length === 0 ? (
              NO_AVAILABILITIES
            ) : (
              <Stack spacing={8} shouldWrapChildren>
                {oneTimeAvailabilities.map(({ teacher, availabilities }) =>
                  availabilities.length > 0 ? (
                    <OneTimeAvailabilitiesMenu
                      teacher={teacher}
                      availabilities={availabilities}
                      handleSelect={(
                        availability: OneTimeAvailabilityState['availability']
                      ) => handleSelect({ teacher, availability })}
                    />
                  ) : null
                )}
              </Stack>
            )}
          </SectionBody>
        </Section>
      </>

      {selected && (
        <ConfirmationModal
          type="one_time"
          teacher={selected.teacher}
          availability={selected.availability}
          subject={subject}
          isOneOnOne={isOneOnOne}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default withLayout(OneTimeClassMenu);
