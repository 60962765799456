import React from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';

import { withLayout, withSEO } from 'components/_hoc';
import { BreadcrumbNav } from 'components/_lib';
import { useActiveStudent } from 'hooks';
import HistoryTable from './HistoryTable';

/**
 * Wraps parent complete class history page
 */
const HistoryPage: FC<{}> = () => {
  const student = useActiveStudent()!;
  const location = useLocation();

  return (
    <>
      <BreadcrumbNav
        links={[
          { href: '/parent/home', text: 'Home', isCurrentPage: false },
          {
            href: location.pathname,
            text: 'History',
            isCurrentPage: true,
          },
        ]}
      />
      <HistoryTable student={student} />
    </>
  );
};

export default withSEO(withLayout(HistoryPage), {
  title: 'History • Parent',
  description: 'Student class history.',
  pathname: '/parent/home/history',
});
