import React, { useState } from 'react';
import type { FC } from 'react';
import { navigate } from 'gatsby';

import {
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/core';

import { useActiveStudent } from 'hooks';
import ChangeBookingBody from './ChangeBookingBody';
import NewClassBody from './NewClassBody';
import ThankYouBody from './ThankYouBody';
import type { ConfirmationModalContainerProps } from './types';

enum ScreensEnum {
  new,
  change,
  thankYou,
}

const navigateHome = (): Promise<void> => navigate('/parent/home');

/**
 * Wraps modal to confirm new clases/bookings
 */
const ConfirmationModalContainer: FC<ConfirmationModalContainerProps> = ({
  type,
  teacher,
  availability,
  bookingToChange,
  subject,
  isOneOnOne,
  isOpen,
  onClose,
}: ConfirmationModalContainerProps) => {
  // Select active student ID
  const student = useActiveStudent()!;

  // Current modal screen
  const [screen, setScreen] = useState<ScreensEnum>(
    type === 'change' ? ScreensEnum.change : ScreensEnum.new
  );

  /**
   * Move to thank you screen
   */
  const transitionToThankYou: VoidFunction = () => {
    setScreen(ScreensEnum.thankYou);
  };

  return (
    <Modal
      size={['full', null, 'lg']}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      preserveScrollBarGap
      closeOnOverlayClick={screen !== ScreensEnum.thankYou}
    >
      <ModalOverlay alignSelf="flex-start" />
      <ModalContent borderRadius={['none', null, '8px']}>
        <IconButton
          variant="ghost"
          variantColor="teal"
          aria-label="close"
          icon="close"
          w="min-content"
          alignSelf="flex-start"
          rounded={8}
          mt={2}
          ml={2}
          onClick={screen === ScreensEnum.thankYou ? navigateHome : onClose}
        />
        <ModalBody
          d="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {screen === ScreensEnum.new ? (
            <NewClassBody
              type={type}
              teacher={teacher}
              availability={availability}
              student={student}
              isOneOnOne={isOneOnOne}
              subject={subject}
              transitionToThankYou={transitionToThankYou}
            />
          ) : screen === ScreensEnum.change ? (
            <ChangeBookingBody
              bookingToChange={bookingToChange!}
              teacher={teacher}
              availability={availability}
              student={student}
              subject={subject}
              transitionToThankYou={transitionToThankYou}
            />
          ) : (
            <ThankYouBody type={type} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModalContainer;
