import React, { memo, useState } from 'react';
import type { FC } from 'react';
import { useMutation } from 'react-query';

import { Heading, Stack, Text } from '@chakra-ui/core';

import API from 'api';
import { EinsteinButton, ErrorMessage } from 'components/_lib';
import type { ErrorState, Subscription } from 'types';
import type { PlanElement } from '../plans';

// ChangeBody component prop types
interface ChangeBodyProps {
  subscription: Subscription;
  plan: PlanElement;
  navigateToThankYou: VoidFunction;
  isUpgrade: boolean;
  coupon?: string;
}

/**
 * Modal body for changing a subscription
 */
const ChangeBody: FC<ChangeBodyProps> = ({
  navigateToThankYou,
  subscription,
  plan,
  isUpgrade,
  coupon,
}: ChangeBodyProps) => {
  // Request errors
  const [requestError, setRequestError] = useState<ErrorState>({
    isActive: false,
    message: '',
  });

  const [mutate, { status }] = useMutation(
    async (): Promise<void> => {
      await API.patch(`/subscriptions/${subscription!.id}`, {
        plan: plan.id,
        coupon,
      });
    },
    {
      onSuccess: () => {
        if (requestError.isActive) {
          setRequestError({ isActive: false, message: '' });
        }
        navigateToThankYou();
      },
      onError: () => {
        setRequestError({
          isActive: true,
          message: 'Could not process change',
        });
      },
    }
  );

  return (
    <Stack spacing={8} w="100%" align="center" justify="center" mb={4}>
      <Stack spacing={4} textAlign="center" w="90%" align="center">
        <Heading size="sm" textTransform="uppercase">
          Are you sure?
        </Heading>
        {isUpgrade ? (
          <Text>
            Upon clicking &quot;confirm&quot; below, your plan will be updated
            immediately and your billing cycle will be reset to the current day.
            <br />
            <br />
            You will be charged a cost prorated from your current subscription.
          </Text>
        ) : (
          <Text>
            By clicking &quot;confirm&quot; below, you schedule your plan to be
            downgraded at your next billing cycle.
          </Text>
        )}
        <EinsteinButton
          variant="outline"
          size="lg"
          minW="132px"
          onClick={() => mutate()}
          isLoading={status === 'loading'}
          loadingText="Changing..."
        >
          Confirm
        </EinsteinButton>
        <ErrorMessage isActive={requestError.isActive} w="80%">
          {requestError.message}
        </ErrorMessage>
      </Stack>
    </Stack>
  );
};

export default memo(ChangeBody);
