import React, { memo, useState } from 'react';
import type { FC } from 'react';

import {
  Button,
  Flex,
  IconButton,
  List,
  ListIcon,
  ListItem,
  Stack,
} from '@chakra-ui/core';

/**
 * Recurring class help info
 */
const HelpBanner: FC<{}> = () => {
  const [neverRemindMeAgain, setNeverRemindMeAgain] = useState<boolean>(
    window.localStorage.getItem('neverRemindMeAgain') === 'true'
  );

  const handleClose = (): void => setNeverRemindMeAgain(true);

  const handleNeverRemindMeAgain = (): void => {
    window.localStorage.setItem('neverRemindMeAgain', 'true');
    handleClose();
  };

  return (
    <Flex
      d={neverRemindMeAgain ? 'none' : 'flex'}
      pos="absolute"
      bg="orange.500"
      zIndex={9999}
      top={0}
      left={0}
      right={0}
      align="center"
      justify="center"
      color="white"
      p={8}
    >
      <Stack spacing={8} align="center" justify="center" maxW="90%">
        <List spacing={2}>
          <ListItem>
            <ListIcon icon="check-circle" />
            Pick a day of the week and class time.
          </ListItem>
          <ListItem>
            <ListIcon icon="check-circle" />
            The class will recur at the same time and day each week.
          </ListItem>
          <ListItem>
            <ListIcon icon="warning" />
            The below times are shown in your local timezone, but are subject to
            change at Daylight Savings Time based on the teacher&apos;s
            timezone.
          </ListItem>
        </List>
        <Flex flexDir={['column', null, 'row']}>
          <Button
            mr={[0, null, 8]}
            mb={[4, null, 0]}
            variant="link"
            variantColor="white"
            textDecor="underline"
            onClick={handleClose}
          >
            Remind me next time
          </Button>
          <Button
            variant="link"
            variantColor="white"
            textDecor="underline"
            onClick={handleNeverRemindMeAgain}
          >
            Never remind me again
          </Button>
        </Flex>
      </Stack>
      <IconButton
        d={['none', null, 'block']}
        aria-label="close"
        pos="absolute"
        variantColor="white"
        variant="outline"
        borderRadius="100%"
        right={[5, null, 10]}
        ml="auto"
        icon="close"
        onClick={handleClose}
      />
    </Flex>
  );
};

export default memo(HelpBanner);
