import React from 'react';
import type { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';

import {
  Heading,
  Icon,
  List,
  ListIcon,
  ListItem,
  PseudoBox,
  SimpleGrid,
  Stack,
} from '@chakra-ui/core';
import type { PseudoBoxProps } from '@chakra-ui/core';
import { FaSpaceShuttle } from 'react-icons/fa';

import {
  BreadcrumbNav,
  MobileSection,
  MobileSectionGroup,
  MobileSectionItemLink,
  MobileSectionTitle,
  Section,
  SectionBody,
  SectionTitle,
} from 'components/_lib';
import { withLayout } from 'components/_hoc';
import { capitalize } from 'utils';

interface ClassType {
  id: string;
  name: string;
  description: string[];
}

const CLASS_TYPES: ClassType[] = [
  {
    id: 'recurring',
    name: 'Recurring',
    description: [
      'Pick a day of the week and class time.',
      'Classes recur on a weekly basis.',
    ],
  },
  {
    id: 'one_time',
    name: 'One-Time',
    description: [
      'Individual classes.',
      'Requires class credits (1 credit = 1 class).',
    ],
  },
];

// ClassTypeMenuOption component prop types
type ClassTypeMenuOptionProps = Omit<PseudoBoxProps, 'children'> &
  GatsbyLinkProps<HTMLElement> &
  Omit<ClassType, 'id'>;

/**
 * Menu option to select class type
 */
const ClassTypeMenuOption: FC<ClassTypeMenuOptionProps> = ({
  name,
  description,
  ...rest
}: ClassTypeMenuOptionProps) => (
  <PseudoBox
    as={GatsbyLink as any}
    color="teal.500"
    p={8}
    _hover={{ bg: 'gray.100' }}
    _active={{ bg: 'gray.200' }}
    cursor="pointer"
    borderRadius={8}
    {...rest}
  >
    <Stack spacing={4} align="center" justify="center" textAlign="center">
      <Heading fontWeight="bold" size="md">
        {name}
      </Heading>
      <List spacing={2} textAlign="left">
        {description.map((text) => (
          <ListItem key={text}>
            <ListIcon icon={FaSpaceShuttle} color="teal.600" />
            {text}
          </ListItem>
        ))}
      </List>
    </Stack>
  </PseudoBox>
);

/**
 * Mobile menu option to select class type
 */
const MobileClassTypeMenuOption: FC<ClassTypeMenuOptionProps> = ({
  name,
  description,
  ...rest
}: ClassTypeMenuOptionProps) => (
  <MobileSectionItemLink
    _hover={{ bg: 'gray.100' }}
    _active={{ bg: 'gray.200' }}
    _last={{ roundedBottom: 8 }}
    cursor="pointer"
    as={GatsbyLink as any}
    {...rest}
  >
    <Stack spacing={4} align="start">
      <Heading fontWeight="bold" size="md">
        {name}
      </Heading>
      <List spacing={2}>
        {description.map((text) => (
          <ListItem key={text}>
            <ListIcon icon={FaSpaceShuttle} color="teal.600" />
            {text}
          </ListItem>
        ))}
      </List>
    </Stack>
    <Icon name="chevron-right" size="2rem" />
  </MobileSectionItemLink>
);

// ClassTypeMenu component prop types
interface ClassTypeMenuProps {
  subject: string;
}

/**
 * Menu to choose a class type
 */
const ClassTypeMenu: FC<ClassTypeMenuProps> = ({
  subject,
}: ClassTypeMenuProps) => (
  <>
    {/* Mobile version */}
    <MobileSection>
      <MobileSectionTitle backTo="/parent/classes">
        Choose a Class Type
      </MobileSectionTitle>
      <MobileSectionGroup>
        {CLASS_TYPES.map(({ id, name, description }) => (
          <MobileClassTypeMenuOption
            key={id}
            to={`/parent/classes/${subject}/${id}`}
            name={name}
            description={description}
          />
        ))}
      </MobileSectionGroup>
    </MobileSection>

    {/* Section rendered on large screens */}
    <>
      <BreadcrumbNav
        links={[
          {
            href: '/parent/classes',
            text: capitalize(subject),
            isCurrentPage: false,
          },
          {
            href: `/parent/classes/${subject}`,
            text: 'Type',
            isCurrentPage: true,
          },
        ]}
      />
      <Section>
        <SectionTitle>Choose a Class Type</SectionTitle>
        <SectionBody>
          <SimpleGrid columns={2} spacing={8}>
            {CLASS_TYPES.map(({ id, name, description }) => (
              <ClassTypeMenuOption
                key={id}
                to={`/parent/classes/${subject}/${id}`}
                name={name}
                description={description}
              />
            ))}
          </SimpleGrid>
        </SectionBody>
      </Section>
    </>
  </>
);

export default withLayout(ClassTypeMenu);
